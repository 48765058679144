import React, { useEffect, useState, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter, // この行を追加
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { fetchDeliveryPlansRequest } from "../../store/deliveryPlan/actions"
import { updateDeliveryPlanRequest } from "../../store/deliveryPlan/actions"
import ErrorMessage from "../Common/ErrorMessage"
import LoadingOverlay from "../Common/LoadingOverlay"
import {
  GoogleMap,
  useJsApiLoader,
  Polyline,
  Marker,
} from "@react-google-maps/api"
import { decode } from "@googlemaps/polyline-codec"

const googleMapsApiKey = process.env.REACT_APP_GOOGLE_API_KEY

const formatDateTime = dateTimeString => {
  if (!dateTimeString) return ""
  const date = new Date(dateTimeString)
  return new Intl.DateTimeFormat("ja-JP", {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  }).format(date)
}

const DeliveryPlanShow = () => {
  const dispatch = useDispatch()

  const isLoadingPlans = useSelector(state => state.deliveryPlanReducer.loading)
  const deliveryPlans =
    useSelector(state => state.deliveryPlanReducer.deliveryPlans) || []
  const deliveryPlanDetail =
    useSelector(state => state.deliveryPlanReducer.deliveryPlanDetail) || {}

  const [selectedPlan, setSelectedPlan] = useState(null)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [decodedRoute, setDecodedRoute] = useState([])
  const mapRef = useRef(null)
  const [isResetModalOpen, setIsResetModalOpen] = useState(false)

  useEffect(() => {
    dispatch(fetchDeliveryPlansRequest({ type: 3 }))
  }, [dispatch])

  useEffect(() => {
    console.log("deliveryPlans", deliveryPlans)
  }, [deliveryPlans])

  useEffect(() => {
      // 計画リストを更新するために再フェッチ
      console.log("deliveryPlanDetail", deliveryPlanDetail)
      if ( deliveryPlanDetail && deliveryPlanDetail.id > 0) {        
        dispatch(fetchDeliveryPlansRequest({ type: 3 }))
      }
      
  }, [deliveryPlanDetail])

  const toggleModal = () => setIsModalOpen(!isModalOpen)
  const toggleResetModal = () => setIsResetModalOpen(!isResetModalOpen)

  const handleShowDetails = plan => {
    setSelectedPlan(plan)
    if (plan.route) {
      const decodedPath = decode(plan.route.route)
      setDecodedRoute(decodedPath)
    }
    toggleModal()
  }

  const handleResetPlan = () => {
    if (selectedPlan) {
      const updatedPlan = {
        ...selectedPlan,
        status: 1,
      }
      dispatch(updateDeliveryPlanRequest({ delivery_plan: updatedPlan }))
      toggleResetModal()
      toggleModal()
    }
  }

  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: googleMapsApiKey,
    libraries: ["places"],
  })

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="ホーム" breadcrumbItem="配送計画選択" />
          <ErrorMessage />
          <LoadingOverlay isLoading={isLoadingPlans} />
          <Row>
            <Col xs={12}>
              <Card>
                <CardBody>
                  <h2>配送計画</h2>
                  {deliveryPlans.length > 0 ? (
                    <Table className="table">
                      <thead>
                        <tr>
                          <th>計画No</th>
                          <th>担当者</th>
                          <th>ステータス</th>
                          <th>開始時間</th>
                          <th>終了時間</th>
                          <th>実績開始時間</th>
                          <th>実績終了時間</th>
                          <th>操作</th>
                        </tr>
                      </thead>
                      <tbody>
                        {deliveryPlans.map((plan, index) => (
                          <tr key={index}>
                            <td>{plan.id}</td>
                            <td>{plan.user_name}</td>
                            <td>{plan.status_name}</td>
                            <td>{formatDateTime(plan.start_date)}</td>
                            <td>{formatDateTime(plan.end_date)}</td>
                            <td>{formatDateTime(plan.actual_start_date)}</td>
                            <td>{formatDateTime(plan.actual_end_date)}</td>
                            <td>
                              <Button
                                color="secondary"
                                onClick={() => handleShowDetails(plan)}
                              >
                                詳細
                              </Button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  ) : (
                    <p>割り当てられた配信計画がありません。</p>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        {selectedPlan && (
          <Modal isOpen={isModalOpen} toggle={toggleModal} size="lg">
            <ModalHeader toggle={toggleModal}>
              配送計画詳細
            </ModalHeader>
            <ModalBody>
              <h5>計画No: {selectedPlan.id}</h5>
              <h5>担当者: {selectedPlan.user_name}</h5>
              <h5>ステータス: {selectedPlan.status_name}</h5>
              {selectedPlan && selectedPlan.status >= 2 && (
                <Button
                  color="danger"
                  size="sm"
                  className="float-right"
                  onClick={toggleResetModal}
                >
                  未実施に戻す
                </Button>
              )}             
              <Table className="table">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>地点名</th>
                    <th>住所</th>
                    <th>移動開始時間</th>
                    <th>到着時間</th>
                    <th>補充開始時間</th>
                    <th>補充終了時間</th>
                  </tr>
                </thead>
                <tbody>
                  {selectedPlan.locations.waypoints.map((location, index) => (
                    <tr key={index}>
                      <th scope="row">{String.fromCharCode(65 + index)}</th>
                      <td>{location.name}</td>
                      <td>{location.address}</td>
                      <td>{formatDateTime(location.start_moving_time)}</td>
                      <td>{formatDateTime(location.end_moving_time)}</td>
                      <td>{formatDateTime(location.start_refilling_time)}</td>
                      <td>{formatDateTime(location.end_refilling_time)}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              {isLoaded && !loadError && (
                <GoogleMap
                  mapContainerStyle={{ height: "400px", width: "100%" }}
                  onLoad={map => {
                    mapRef.current = map
                    if (decodedRoute.length > 0) {
                      const bounds = new window.google.maps.LatLngBounds()
                      decodedRoute.forEach(([lat, lng]) =>
                        bounds.extend({ lat, lng })
                      )
                      map.fitBounds(bounds)
                    }
                  }}
                >
                  {decodedRoute.length > 0 && (
                    <Polyline
                      path={decodedRoute.map(([lat, lng]) => ({
                        lat,
                        lng,
                      }))}
                      options={{ strokeColor: "#FF0000" }}
                    />
                  )}
                  {selectedPlan.route.waypoints_info &&
                    selectedPlan.route.waypoints_info.map((point, index) => (
                      <Marker
                        key={index}
                        position={point.location}
                        label={String.fromCharCode(65 + index)} // A, B, Cなど
                      />
                    ))}
                </GoogleMap>
              )}
            </ModalBody>
          </Modal>
        )}
        <Modal isOpen={isResetModalOpen} toggle={toggleResetModal}>
          <ModalHeader toggle={toggleResetModal}>確認</ModalHeader>
          <ModalBody>
            既にこの配送計画は実施中ですが、未実施に戻しますか？
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={handleResetPlan}>
              OK
            </Button>{' '}
            <Button color="secondary" onClick={toggleResetModal}>
              キャンセル
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    </React.Fragment>
  )
}

export default DeliveryPlanShow
