import {
  FETCH_CAMERA_INFO,
  FETCH_CAMERA_INFO_SUCCESS,
  FETCH_CAMERA_INFO_ERROR,
  UPDATE_CAMERA_INFO,
  UPDATE_CAMERA_INFO_SUCCESS,
  UPDATE_CAMERA_INFO_ERROR,
} from "./actionTypes"

// Fetch graph data action
export const fetchCameraInfo = (uuid) => ({  // cameraId を uuid に変更
  type: FETCH_CAMERA_INFO,
  payload: uuid,
})

// Fetch graph data success action
export const fetchCameraInfoSuccess = (data) => ({
  type: FETCH_CAMERA_INFO_SUCCESS,
  payload: data,
})

// Fetch graph data error action
export const fetchCameraInfoError = (error) => ({
  type: FETCH_CAMERA_INFO_ERROR,
  payload: error,
})

export const updateCameraInfo = (data) => ({
  type: UPDATE_CAMERA_INFO,
  payload: data,
})

// Fetch graph data success action
export const updateCameraInfoSuccess = (data) => ({
  type: UPDATE_CAMERA_INFO_SUCCESS,
  payload: data,
})

// Fetch graph data error action
export const updateCameraInfoError = (error) => ({
  type: UPDATE_CAMERA_INFO_ERROR,
  payload: error,
})
