import {
  FETCH_LINE_USERS,
  FETCH_LINE_USERS_SUCCESS,
  FETCH_LINE_USERS_FAIL,
  POST_LINK_USER_SUCCESS,
  POST_LINK_USER_FAIL,
  DELETE_LINE_USER_SUCCESS,
  DELETE_LINE_USER_FAIL,
  PUT_LINK_USER_SUCCESS,
  PUT_LINK_USER_FAIL,
} from "./actionTypes"

const initialState = {
  lineUsers: { results: [] },
  loading: false,
  error: null,
}

const lineUserReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_LINE_USERS:
      return {
        ...state,
        loading: true,
      }
    case FETCH_LINE_USERS_SUCCESS:
      return {
        ...state,
        lineUsers: action.payload,
        loading: false,
        error: null,
      }
    case FETCH_LINE_USERS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case POST_LINK_USER_SUCCESS:
    case PUT_LINK_USER_SUCCESS:
      return {
        ...state,
        lineUsers: {
          ...state.lineUsers,
          results: state.lineUsers.results.map(user =>
            user.id === action.payload.id ? action.payload : user
          ),
        },
        error: null,
      }
    case DELETE_LINE_USER_SUCCESS:
      return {
        ...state,
        lineUsers: {
          ...state.lineUsers,
          results: state.lineUsers.results.filter(
            user => user.id !== action.payload
          ),
        },
        error: null,
      }
    case POST_LINK_USER_FAIL:
    case PUT_LINK_USER_FAIL:
    case DELETE_LINE_USER_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    default:
      return state
  }
}

export default lineUserReducer
