export const FETCH_LINE_USERS = "FETCH_LINE_USERS"
export const FETCH_LINE_USERS_SUCCESS = "FETCH_LINE_USERS_SUCCESS"
export const FETCH_LINE_USERS_FAIL = "FETCH_LINE_USERS_FAIL"

export const DELETE_LINE_USER = "DELETE_LINE_USER"
export const DELETE_LINE_USER_SUCCESS = "DELETE_LINE_USER_SUCCESS"
export const DELETE_LINE_USER_FAIL = "DELETE_LINE_USER_FAIL"

export const POST_LINK_USER = "POST_LINK_USER"
export const POST_LINK_USER_SUCCESS = "POST_LINK_USER_SUCCESS"
export const POST_LINK_USER_FAIL = "POST_LINK_USER_FAIL"

export const PUT_LINK_USER = "PUT_LINK_USER"
export const PUT_LINK_USER_SUCCESS = "PUT_LINK_USER_SUCCESS"
export const PUT_LINK_USER_FAIL = "PUT_LINK_USER_FAIL"
