import { call, put, takeLatest } from "redux-saga/effects"
import { FETCH_CAMERA_INFO, UPDATE_CAMERA_INFO } from "./actionTypes"
import {
  fetchCameraInfoSuccess,
  fetchCameraInfoError,
  updateCameraInfoSuccess,
  updateCameraInfoError,
} from "./actions"
import { getCameraInfo, updateCameraInfo } from "../../helpers/backend_helper"

function* fetchCameraInfoSaga(action) {
  try {
    const response = yield call(getCameraInfo, action.payload)  // action.payload は now uuid
    yield put(fetchCameraInfoSuccess(response))
  } catch (error) {
    yield put(fetchCameraInfoError(error))
  }
}

function* updateCameraInfoSaga(action) {
  try {
    const response = yield call(updateCameraInfo, action.payload)
    yield put(updateCameraInfoSuccess(response))
  } catch (error) {
    yield put(updateCameraInfoError(error))
  }
}

function* cameraInfoSaga() {
  yield takeLatest(FETCH_CAMERA_INFO, fetchCameraInfoSaga)
  yield takeLatest(UPDATE_CAMERA_INFO, updateCameraInfoSaga)
}

export default cameraInfoSaga
