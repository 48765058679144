export const FETCH_CAMERAS_DATA = "FETCH_CAMERAS_DATA"
export const FETCH_CAMERAS_DATA_SUCCESS = "FETCH_CAMERAS_DATA_SUCCESS"
export const FETCH_CAMERAS_DATA_ERROR = "FETCH_CAMERAS_DATA_ERROR"
export const UPDATE_CAMERAS = "UPDATE_CAMERAS"
export const UPDATE_CAMERAS_SUCCESS = "UPDATE_CAMERAS_SUCCESS"
export const UPDATE_CAMERAS_ERROR = "UPDATE_CAMERAS_ERROR"
export const REMOVE_IPC_FROM_CAMERA = "REMOVE_IPC_FROM_CAMERA"
export const REMOVE_IPC_FROM_CAMERA_SUCCESS = "REMOVE_IPC_FROM_CAMERA_SUCCESS"
export const REMOVE_IPC_FROM_CAMERA_ERROR = "REMOVE_IPC_FROM_CAMERA_ERROR"
export const FETCH_CAMERA_INFO = "FETCH_CAMERA_INFO"
export const FETCH_CAMERA_INFO_SUCCESS = "FETCH_CAMERA_INFO_SUCCESS"
export const FETCH_CAMERA_INFO_ERROR = "FETCH_CAMERA_INFO_ERROR"
export const UPDATE_CAMERA_INFO = "UPDATE_CAMERA_INFO"
export const UPDATE_CAMERA_INFO_SUCCESS = "UPDATE_CAMERA_INFO_SUCCESS"
export const UPDATE_CAMERA_INFO_ERROR = "UPDATE_CAMERA_INFO_ERROR"
