import * as actionTypes from "./actionTypes"

export const fetchCompanies = () => ({
  type: actionTypes.FETCH_COMPANIES_REQUEST,
})

export const fetchCompaniesSuccess = companies => ({
  type: actionTypes.FETCH_COMPANIES_SUCCESS,
  payload: companies.data.results,
})

export const fetchCompaniesFailure = error => ({
  type: actionTypes.FETCH_COMPANIES_FAILURE,
  error,
})

export const registerCompany = newCompany => ({
  type: actionTypes.REGISTER_COMPANY_REQUEST,
  newCompany,
})

export const registerCompanySuccess = companyData => ({
  type: actionTypes.REGISTER_COMPANY_SUCCESS,
  payload: companyData.data.results,
})

export const registerCompanyFailure = error => ({
  type: actionTypes.REGISTER_COMPANY_FAILURE,
  error,
})

export const updateCompany = (companyId, companyData) => ({
  type: actionTypes.UPDATE_COMPANY_REQUEST,
  companyId,
  companyData,
})

export const updateCompanySuccess = companyData => ({
  type: actionTypes.UPDATE_COMPANY_SUCCESS,
  payload: companyData.data.results,
})

export const updateCompanyFailure = error => ({
  type: actionTypes.UPDATE_COMPANY_FAILURE,
  error,
})

export const deleteCompany = companyId => ({
  type: actionTypes.DELETE_COMPANY_REQUEST,
  companyId,
})

export const deleteCompanySuccess = companyData => ({
  type: actionTypes.DELETE_COMPANY_SUCCESS,
  payload: companyData.data.results,
})

export const deleteCompanyFailure = error => ({
  type: actionTypes.DELETE_COMPANY_FAILURE,
  error,
})
