import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  FormGroup,
  Label,
  Input,
  ModalFooter,
  Table,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import ErrorMessage from "../Common/ErrorMessage"
import LoadingOverlay from "../Common/LoadingOverlay"
import {
  fetchVehicles,
  addVehicle,
  updateVehicle,
  deleteVehicle,
  fetchVehicleStock,
} from "../../store/vehicle/actions"
import { fetchCompanies } from "../../store/companies/actions"
import { use } from "i18next"

const Delivery = () => {
  const dispatch = useDispatch()

  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false)
  const [isNewModalOpen, setIsNewModalOpen] = useState(false)
  const [selectedDelivery, setSelectedDelivery] = useState(null)
  const [newDelivery, setNewDelivery] = useState({
    company_id: "",
    vehicle_code: "",
    vehicle_name: "",
    address: "",
    comment: "",
  })

  const vehicles = useSelector(state => state.vehicleReducer.vehicles || [])
  const isLoading = useSelector(state => state.vehicleReducer.loading)
  const companies = useSelector(
    state => state.companyReducer.companiesData || []
  )
  const vehicleStocks = useSelector(
    state => state.vehicleReducer.vehicleStock || []
  )

  useEffect(() => {
    dispatch(fetchVehicles())
    dispatch(fetchCompanies())
  }, [dispatch])

  useEffect(() => {
    console.log("vehicleStocks", vehicleStocks)
  }, [vehicleStocks])

  const handleOpenDetailsModal = delivery => {
    dispatch(fetchVehicleStock(delivery.vehicle_id))
    setSelectedDelivery(delivery)
    setIsDetailsModalOpen(true)
  }

  const handleOpenNewModal = () => {
    setIsNewModalOpen(true)
  }

  const handleCloseNewModal = () => {
    setIsNewModalOpen(false)
    setNewDelivery({
      company_id: "",
      vehicle_code: "",
      vehicle_name: "",
      address: "",
      comment: "",
    })
  }

  const handleAddNewDelivery = async () => {
    dispatch(addVehicle(newDelivery))
    setIsNewModalOpen(false)
  }

  const handleUpdateDelivery = async () => {
    dispatch(updateVehicle(selectedDelivery))
    setIsDetailsModalOpen(false)
  }

  const handleDeleteVehicle = vehicleId => {
    if (window.confirm("本当にこの配送車を削除しますか？")) {
      dispatch(deleteVehicle(vehicleId))
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            maintitle="main"
            title="管理"
            breadcrumbItem="配送車管理"
          />
          <ErrorMessage />
          <LoadingOverlay isLoading={isLoading} />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <CardTitle>配送車リスト</CardTitle>
                  <Button
                    color="primary"
                    className="mb-3"
                    onClick={handleOpenNewModal}
                  >
                    新規登録
                  </Button>
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>配送車コード</th>
                          <th>配送車名</th>
                          <th>コメント</th>
                          <th>会社</th>
                          <th>アクション</th>
                        </tr>
                      </thead>
                      <tbody>
                        {vehicles.map(vehicle => (
                          <tr key={vehicle.vehicle_id}>
                            <td>{vehicle.vehicle_code}</td>
                            <td>{vehicle.vehicle_name}</td>
                            <td>{vehicle.comment || "-"}</td>
                            <td>{vehicle.company_name}</td>
                            <td onClick={e => e.stopPropagation()}>
                              <Button
                                color="info"
                                onClick={() => handleOpenDetailsModal(vehicle)}
                              >
                                修正
                              </Button>{" "}
                              <Button
                                color="danger"
                                onClick={() =>
                                  handleDeleteVehicle(vehicle.vehicle_id)
                                }
                              >
                                削除
                              </Button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {/* 新規登録モーダル */}
          <Modal isOpen={isNewModalOpen} toggle={handleCloseNewModal}>
            <ModalHeader toggle={handleCloseNewModal}>
              新規配送車登録
            </ModalHeader>
            <ModalBody>
              <FormGroup>
                <Label htmlFor="companySelect">会社選択</Label>
                <Input
                  type="select"
                  id="companySelect"
                  value={newDelivery.company_id || ""}
                  onChange={e =>
                    setNewDelivery({
                      ...newDelivery,
                      company_id: e.target.value,
                    })
                  }
                >
                  <option value="">選択してください</option>
                  {companies.map(company => (
                    <option key={company.id} value={company.id}>
                      {company.name}
                    </option>
                  ))}
                </Input>
              </FormGroup>
              <FormGroup>
                <Label htmlFor="vehicleCode">配送車コード</Label>
                <Input
                  id="vehicleCode"
                  value={newDelivery.vehicle_code}
                  onChange={e =>
                    setNewDelivery({
                      ...newDelivery,
                      vehicle_code: e.target.value,
                    })
                  }
                />
              </FormGroup>
              <FormGroup>
                <Label htmlFor="vehicleName">配送車名</Label>
                <Input
                  id="vehicleName"
                  value={newDelivery.vehicle_name}
                  onChange={e =>
                    setNewDelivery({
                      ...newDelivery,
                      vehicle_name: e.target.value,
                    })
                  }
                />
              </FormGroup>
              <FormGroup>
                <Label htmlFor="comment">コメント</Label>
                <Input
                  id="comment"
                  value={newDelivery.comment}
                  onChange={e =>
                    setNewDelivery({ ...newDelivery, comment: e.target.value })
                  }
                />
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={handleCloseNewModal}>
                キャンセル
              </Button>
              <Button color="primary" onClick={handleAddNewDelivery}>
                保存
              </Button>
            </ModalFooter>
          </Modal>
          {/* 修正モーダル */}
          <Modal
            isOpen={isDetailsModalOpen}
            toggle={() => setIsDetailsModalOpen(false)}
          >
            <ModalHeader toggle={() => setIsDetailsModalOpen(false)}>
              配送車情報修正
            </ModalHeader>
            <ModalBody>
              {selectedDelivery && (
                <>
                  <FormGroup>
                    <Label htmlFor="companySelect">会社選択</Label>
                    <Input
                      type="select"
                      id="companySelect"
                      value={selectedDelivery.company_id || ""}
                      onChange={e =>
                        setSelectedDelivery({
                          ...selectedDelivery,
                          company_id: e.target.value,
                        })
                      }
                    >
                      <option value="">選択してください</option>
                      {companies.map(company => (
                        <option key={company.id} value={company.id}>
                          {company.name}
                        </option>
                      ))}
                    </Input>
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="vehicleCode">配送車コード</Label>
                    <Input
                      id="vehicleCode"
                      value={selectedDelivery.vehicle_code}
                      onChange={e =>
                        setSelectedDelivery({
                          ...selectedDelivery,
                          vehicle_code: e.target.value,
                        })
                      }
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="vehicleName">配送車名</Label>
                    <Input
                      id="vehicleName"
                      value={selectedDelivery.vehicle_name}
                      onChange={e =>
                        setSelectedDelivery({
                          ...selectedDelivery,
                          vehicle_name: e.target.value,
                        })
                      }
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="comment">コメント</Label>
                    <Input
                      id="comment"
                      value={selectedDelivery.comment || ""}
                      onChange={e =>
                        setSelectedDelivery({
                          ...selectedDelivery,
                          comment: e.target.value,
                        })
                      }
                    />
                  </FormGroup>
                  {/* Vehicle Stocks Table */}
                  <h5>Vehicle Stocks</h5>
                  <Table>
                    <thead>
                      <tr>
                        <th>商品コード</th>
                        <th>商品名</th>
                        <th>数量</th>
                      </tr>
                    </thead>
                    <tbody>
                      {vehicleStocks.map(stock => (
                        <tr key={stock.goods_id}>
                          <td>{stock.goods_no}</td>
                          <td>{stock.goods_name}</td>
                          <td>{stock.stock_count}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </>
              )}
            </ModalBody>
            <ModalFooter>
              <Button
                color="secondary"
                onClick={() => setIsDetailsModalOpen(false)}
              >
                キャンセル
              </Button>
              <Button color="primary" onClick={handleUpdateDelivery}>
                保存
              </Button>
            </ModalFooter>
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Delivery
