import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Form,
  FormFeedback,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap"

// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"

// Redux
import { connect, useDispatch, useSelector } from "react-redux"
import withRouter from "components/Common/withRouter"

// Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"

import avatar from "../../assets/images/users/user-4.jpg"
// actions
import {
  editProfile,
  resetProfileFlag,
  changePassword,
} from "../../store/auth/profile/actions"

import LoadingOverlay from "../Common/LoadingOverlay"
import ErrorMessage from "../Common/ErrorMessage"

// LINE ユーザー関連のアクションをインポート
import { fetchLineUsers, postLineUser, deleteLineUser } from "../../store/lineUser/actions"

const UserProfile = props => {
  const dispatch = useDispatch()

  const [email, setemail] = useState("")
  const [name, setname] = useState("")
  const [idx, setidx] = useState(1)
  const [isLineButtonDisabled, setIsLineButtonDisabled] = useState(false)
  const [showLineButton, setShowLineButton] = useState(true)
  const [currentUserId, setCurrentUserId] = useState(null)
  const [isUnlinkModalOpen, setIsUnlinkModalOpen] = useState(false)
  const [selectedLineUserId, setSelectedLineUserId] = useState(null)
  const [selectedLineUser, setSelectedLineUser] = useState(null)
  const [isLineUserListModalOpen, setIsLineUserListModalOpen] = useState(false)
  const [isLineActionModalOpen, setIsLineActionModalOpen] = useState(false)
  const [isLinkModalOpen, setIsLinkModalOpen] = useState(false)
  const [unlinkedLineUsers, setUnlinkedLineUsers] = useState([])
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false)
  const [confirmAction, setConfirmAction] = useState(null)

  const isLoading = useSelector(state => state.profileReducer.loading)
  const message = useSelector(state => state.profileReducer.message)
  const lineUsers = useSelector(state => state.lineUserReducer.lineUsers.line_users || [])
  const isDisplay = useSelector(state => state.lineUserReducer.lineUsers.is_display)

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"))
      if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
        setname(obj.displayName)
        setemail(obj.email)
        setidx(obj.uid || 1)
      } else if (
        process.env.REACT_APP_DEFAULTAUTH === "fake" ||
        process.env.REACT_APP_DEFAULTAUTH === "jwt"
      ) {
        setname(obj.name)
        setemail(obj.email)
        setidx(obj.uid || 1)
      }
      setCurrentUserId(obj.uid || 1)
      setTimeout(() => {
        props.resetProfileFlag()
      }, 3000)
    }
  }, [props.success])

  useEffect(() => {
    dispatch(fetchLineUsers("user-profile"))
  }, [dispatch])

  useEffect(() => {
    const shouldShowLineButton =
      lineUsers &&
      lineUsers.every(user => {
        if (user.user_id === currentUserId) return false;
        if (!user.is_processing) return true;
        const createdAt = new Date(user.created_at);
        const fifteenMinutesAgo = new Date(Date.now() - 15 * 60 * 1000);
        return createdAt < fifteenMinutesAgo;
      });
    
    if (shouldShowLineButton !== showLineButton) {
      setShowLineButton(shouldShowLineButton);
    }
  }, [lineUsers, currentUserId, showLineButton]);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      username: name || "",
      idx: idx || "",
      newPassword: "",
      confirmPassword: "",
      email: email || "",
    },
    validationSchema: Yup.object({
      username: Yup.string().required("ユーザー名を入力してください"),
      email: Yup.string().email("Invalid email address"),
      newPassword: Yup.string(),
      confirmPassword: Yup.string().oneOf(
        [Yup.ref("newPassword"), null],
        "スワードが同じではありません"
      ),
    }),
  })

  const handleUpdate = async () => {
    // Formikのバリデーションを手動で実行
    const errors = await validation.validateForm(validation.values)

    // エラーオブジェクトが空かどうかをチェック
    if (Object.keys(errors).length === 0) {
      // エラーがなければ、dispatchを呼び出す
      dispatch(editProfile(validation.values))
    } else {
      // エラーがあれば、Formikの状態を更新してエラーを表示
      validation.setErrors(errors)
      validation.setTouched({
        username: true,
        email: true,
        newPassword: true,
        confirmPassword: true,
      })
    }
  }

  useEffect(() => {
    if (message) {
      alert(message) // ここではシンプルなalertダイアログを使用
    }
  }, [message])

  const handleLineButtonClick = e => {
    e.preventDefault()
    setIsLineActionModalOpen(true)
  }

  const handleLineAddFriend = () => {
    dispatch(postLineUser())
    window.open("https://lin.ee/jX074kO", "_blank", "noopener,noreferrer")
    setIsLineActionModalOpen(false)
  }

  const handleOpenLinkModal = () => {
    setIsLineActionModalOpen(false)
    // ここで未紐づけのLINEユーザーを取得するAPIを呼び出す
    // 仮のデータを使用
    setUnlinkedLineUsers([
      {
        id: 2,
        display_name: "あべりょうた",
        is_processing: false,
        created_at: "2024-09-24T09:08:47",
        updated_at: "2024-09-30T10:54:12"
      }
    ])
    setIsLinkModalOpen(true)
  }

  const handleLinkUser = (lineUser) => {
    setSelectedLineUser(lineUser)
    setIsConfirmModalOpen(true)
    setConfirmAction('link')
  }

  const handleUnlinkButtonClick = (lineUser) => {
    setSelectedLineUser(lineUser)
    setIsConfirmModalOpen(true)
    setConfirmAction('unlink')
  }

  const handleConfirmAction = () => {
    if (confirmAction === 'link') {
      // ここでバックエンドAPIを呼び出してユーザーを紐づける
      // 例: dispatch(linkLineUser(selectedLineUser.id))
      console.log(`Linking user with ID: ${selectedLineUser.id}`)
    } else if (confirmAction === 'unlink') {
      dispatch(deleteLineUser(selectedLineUser.id))
    }
    setIsConfirmModalOpen(false)
    setIsLinkModalOpen(false)
    setIsLineUserListModalOpen(false)
  }
  
  const handleLineUserListButtonClick = () => {
    setIsLineUserListModalOpen(true)
  }

  UserProfile.propTypes = {
    editProfile: PropTypes.func,
    changePassword: PropTypes.func,
    error: PropTypes.any,
    success: PropTypes.any,
  }

  const mapStatetoProps = state => {
    // state.Profileがundefinedの場合は、デフォルトの値を使用
    const { error, success } = state.Profile || { error: null, success: null }
    return { error, success }
  }

  document.title = "プロファイル"
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb title="main" breadcrumbItem="Profile" />
          <ErrorMessage />
          <LoadingOverlay isLoading={isLoading} />
          <Row>
            <Col lg="12">
              {props.error && props.error ? (
                <Alert color="danger">{props.error}</Alert>
              ) : null}
              {props.success ? (
                <Alert color="success">{props.success}</Alert>
              ) : null}

              <Card>
                <CardBody>
                  <div className="d-flex">
                    <div className="mx-3">
                      <img
                        src={avatar}
                        alt=""
                        className="avatar-md rounded-circle img-thumbnail"
                      />
                    </div>
                    <div className="align-self-center flex-1">
                      <div className="text-muted">
                        <h5>{name}</h5>
                        <p className="mb-1">{email}</p>
                        <p className="mb-0">ID : #{idx}</p>
                      </div>
                    </div>
                  </div>
                  {/* <div className="mt-3 d-flex align-items-center">
                    {isDisplay === 1 && (
                      <Button color="success" onClick={handleLineButtonClick} className="mr-3">
                        LINE連携
                      </Button>
                    )}
                    {lineUsers.length > 0 && (
                      <Button color="danger" onClick={handleLineUserListButtonClick} className="mr-2">
                        LINE解除
                      </Button>
                    )}
                  </div> */}
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Card>
            <CardBody>
              <Form
                className="form-horizontal"
                onSubmit={e => {
                  e.preventDefault()
                  validation.handleSubmit(e)
                }}
              >
                <div className="form-group">
                  <Label className="form-label">ユーザー名</Label>
                  <Input
                    name="username"
                    className="form-control"
                    type="text"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.username || ""}
                    invalid={
                      validation.touched.username && validation.errors.username
                        ? true
                        : false
                    }
                  />
                  {validation.touched.username && validation.errors.username ? (
                    <FormFeedback type="invalid">
                      {validation.errors.username}
                    </FormFeedback>
                  ) : null}
                  <Input name="idx" value={idx} type="hidden" />
                </div>

                <div className="form-group">
                  <Label className="form-label">メールアドレス</Label>
                  <Input
                    name="email"
                    className="form-control"
                    type="email"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.email || ""}
                    invalid={
                      validation.touched.email && validation.errors.email
                        ? true
                        : false
                    }
                  />
                  {validation.touched.email && validation.errors.email ? (
                    <FormFeedback type="invalid">
                      {validation.errors.email}
                    </FormFeedback>
                  ) : null}
                </div>

                <div className="form-group">
                  <Label className="form-label">新パスワード</Label>
                  <Input
                    name="newPassword"
                    className="form-control"
                    type="password"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.newPassword || ""}
                    invalid={
                      validation.touched.newPassword &&
                      validation.errors.newPassword
                        ? true
                        : false
                    }
                  />
                  {validation.touched.newPassword &&
                  validation.errors.newPassword ? (
                    <FormFeedback type="invalid">
                      {validation.errors.newPassword}
                    </FormFeedback>
                  ) : null}
                </div>
                <div className="form-group">
                  <Label className="form-label">新パスワード確認用</Label>
                  <Input
                    name="confirmPassword"
                    className="form-control"
                    type="password"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.confirmPassword || ""}
                    invalid={
                      validation.touched.confirmPassword &&
                      validation.errors.confirmPassword
                        ? true
                        : false
                    }
                  />
                  {validation.touched.confirmPassword &&
                  validation.errors.confirmPassword ? (
                    <FormFeedback type="invalid">
                      {validation.errors.confirmPassword}
                    </FormFeedback>
                  ) : null}
                </div>
                <div className="text-center mt-4">
                  <button
                    className="btn btn-primary ml-2 flex-shrink-0"
                    onClick={handleUpdate}
                  >
                    更新
                  </button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Container>
      </div>

      {/* LINE操作選択モーダル */}
      <Modal isOpen={isLineActionModalOpen} toggle={() => setIsLineActionModalOpen(false)}>
        <ModalHeader toggle={() => setIsLineActionModalOpen(false)}>LINE連携</ModalHeader>
        <ModalBody>
          <p>どちらの操作を行いますか？</p>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleLineAddFriend}>LINEの友達追加</Button>
          <Button color="secondary" onClick={handleOpenLinkModal}>既存のLINEアカウントと連携</Button>
        </ModalFooter>
      </Modal>

      {/* LINE紐づけモーダル */}
      <Modal isOpen={isLinkModalOpen} toggle={() => setIsLinkModalOpen(false)} size="lg">
        <ModalHeader toggle={() => setIsLinkModalOpen(false)}>LINEアカウント連携</ModalHeader>
        <ModalBody>
          <div className="table-responsive">
            <table className="table table-centered table-nowrap mb-0">
              <thead>
                <tr>
                  <th>LINEユーザー名</th>
                  <th>作成日時</th>
                  <th>更新日時</th>
                  <th>アクション</th>
                </tr>
              </thead>
              <tbody>
                {unlinkedLineUsers.map((user) => (
                  <tr key={user.id}>
                    <td>{user.display_name}</td>
                    <td>{new Date(user.created_at).toLocaleString()}</td>
                    <td>{new Date(user.updated_at).toLocaleString()}</td>
                    <td>
                      <Button
                        color="primary"
                        size="sm"
                        onClick={() => handleLinkUser(user)}
                      >
                        連携
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => setIsLinkModalOpen(false)}>閉じる</Button>
        </ModalFooter>
      </Modal>

      {/* LINEユーザー一覧モーダル */}
      <Modal isOpen={isLineUserListModalOpen} toggle={() => setIsLineUserListModalOpen(false)} size="lg">
        <ModalHeader toggle={() => setIsLineUserListModalOpen(false)}>LINE解除</ModalHeader>
        <ModalBody>
          <div className="table-responsive">
            <table className="table table-centered table-nowrap mb-0">
              <thead>
                <tr>
                  <th>LINEユーザー名</th>
                  <th>作成日時</th>
                  <th>更新日時</th>
                  <th>アクション</th>
                </tr>
              </thead>
              <tbody>
                {lineUsers.map((user) => (
                  <tr key={user.id}>
                    <td>{user.display_name}</td>
                    <td>{new Date(user.created_at).toLocaleString()}</td>
                    <td>{new Date(user.updated_at).toLocaleString()}</td>
                    <td>
                      <Button
                        color="danger"
                        size="sm"
                        onClick={() => handleUnlinkButtonClick(user)}
                      >
                        解除
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => setIsLineUserListModalOpen(false)}>閉じる</Button>
        </ModalFooter>
      </Modal>

      {/* 確認モーダル */}
      <Modal isOpen={isConfirmModalOpen} toggle={() => setIsConfirmModalOpen(false)}>
        <ModalHeader toggle={() => setIsConfirmModalOpen(false)}>
          {confirmAction === 'link' ? 'LINE連携の確認' : 'LINE解除の確認'}
        </ModalHeader>
        <ModalBody>
          {selectedLineUser && (
            <>
              <p>以下のLINEユーザーと{confirmAction === 'link' ? '連携' : '解除'}しますか？</p>
              <p>アプリユーザー名: <strong>{name}</strong></p>
              <p>LINEユーザー名: <strong>{selectedLineUser.display_name}</strong></p>
              <p>この{confirmAction === 'link' ? 'LINEユーザー' : 'ユーザー'}と{confirmAction === 'link' ? 'アプリユーザーの連携を行います' : 'の連携を解除します'}。</p>
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => setIsConfirmModalOpen(false)}>キャンセル</Button>
          <Button color={confirmAction === 'link' ? 'primary' : 'danger'} onClick={handleConfirmAction}>
            {confirmAction === 'link' ? '連携' : '解除'}
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  )
}

UserProfile.propTypes = {
  editProfile: PropTypes.func,
  changePassword: PropTypes.func,
  error: PropTypes.any,
  success: PropTypes.any,
}

const mapStatetoProps = state => {
  // state.Profileがundefinedの場合は、デフォルトの値を使用
  const { error, success } = state.Profile || { error: null, success: null }
  return { error, success }
}

export default withRouter(
  connect(mapStatetoProps, { editProfile, changePassword, resetProfileFlag })(
    UserProfile
  )
)