import React, { useState, useEffect } from "react"
import { useDispatch } from "react-redux"
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  FormGroup,
  Label,
  Input,
} from "reactstrap"
import {
  updateWarehouseStock,
  deleteWarehouseStock,
} from "../../../store/warehouse/actions"
import { FaChevronCircleUp, FaChevronCircleDown } from "react-icons/fa"
import "./UpdateItemWarehouseStockModal.css"

const UpdateItemWarehouseStockModal = ({
  isOpen,
  toggle,
  item,
  warehouses,
  selectedWarehouseId,
}) => {
  const dispatch = useDispatch()

  const [updatedItem, setUpdatedItem] = useState({
    warehouse_stock_id: "",
    warehouse_id: "",
    goods_id: "",
    stock_count: 0,
  })

  const [itemDetails, setItemDetails] = useState({
    goods_no: "",
    goods_name: "",
  })

  const [quantities, setQuantities] = useState([0, 0, 0])
  const [isDeleteChecked, setIsDeleteChecked] = useState(false)
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)

  useEffect(() => {
    if (item) {
      const stockCount = item.stock_count || 0
      setUpdatedItem({
        warehouse_stock_id: item.warehouse_stock_id || "",
        warehouse_id: item.warehouse_id || selectedWarehouseId || "",
        goods_id: item.goods_id || "",
        stock_count: stockCount,
      })
      setItemDetails({
        goods_no: item.goods_no || "",
        goods_name: item.goods_name || "",
      })
      const hundreds = Math.floor(stockCount / 100)
      const tens = Math.floor((stockCount % 100) / 10)
      const ones = stockCount % 10
      setQuantities([hundreds, tens, ones])
    }
    setIsDeleteChecked(false)
    setShowDeleteConfirmation(false)
  }, [item, selectedWarehouseId, isOpen])

  const handleQuantityChange = (index, increment) => {
    setQuantities(prevQuantities => {
      const newQuantities = [...prevQuantities]
      newQuantities[index] = Math.min(
        9,
        Math.max(0, newQuantities[index] + increment)
      )
      return newQuantities
    })
  }

  const handleUpdate = () => {
    if (isDeleteChecked) {
      setShowDeleteConfirmation(true)
    } else {
      const stockCount =
        quantities[0] * 100 + quantities[1] * 10 + quantities[2]
      dispatch(
        updateWarehouseStock({ ...updatedItem, stock_count: stockCount })
      )
      toggle()
    }
  }

  const handleConfirmDelete = () => {
    dispatch(
      deleteWarehouseStock(
        updatedItem.warehouse_stock_id,
        updatedItem.warehouse_id
      )
    )
    toggle()
  }

  const handleResetQuantities = () => {
    setQuantities([0, 0, 0])
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>倉庫在庫商品情報を更新</ModalHeader>
      <ModalBody>
        <FormGroup>
          <Label htmlFor="warehouse_id">倉庫ID</Label>
          <Input
            id="warehouse_id"
            name="warehouse_id"
            type="select"
            value={updatedItem.warehouse_id}
            disabled
          >
            <option value="">倉庫を選択してください</option>
            {warehouses.map(warehouse => (
              <option
                key={warehouse.warehouse_id}
                value={warehouse.warehouse_id}
              >
                {warehouse.warehouse_code} - {warehouse.address}
              </option>
            ))}
          </Input>
        </FormGroup>
        <FormGroup>
          <Label htmlFor="goods_no">商品コード</Label>
          <Input
            id="goods_no"
            name="goods_no"
            type="text"
            value={itemDetails.goods_no}
            disabled
            style={{ backgroundColor: "#e9ecef" }}
          />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="goods_name">商品名</Label>
          <Input
            id="goods_name"
            name="goods_name"
            type="text"
            value={itemDetails.goods_name}
            disabled
            style={{ backgroundColor: "#e9ecef" }}
          />
        </FormGroup>
        <FormGroup>
          <Label>数量</Label>
          <div className="quantity-inputs">
            <button
              type="button"
              className="reset-button"
              onClick={handleResetQuantities}
            >
              ×
            </button>
            {quantities.map((quantity, index) => (
              <div key={index} className="quantity-input">
                <button
                  type="button"
                  className="arrow up"
                  onClick={() => handleQuantityChange(index, 1)}
                >
                  <FaChevronCircleUp style={{ color: "blue" }} />
                </button>
                <input
                  type="number"
                  value={quantity}
                  readOnly
                  className="quantity-number"
                />
                <button
                  type="button"
                  className="arrow down"
                  onClick={() => handleQuantityChange(index, -1)}
                >
                  <FaChevronCircleDown style={{ color: "red" }} />
                </button>
              </div>
            ))}
            <span className="unit-label">個</span>
          </div>
        </FormGroup>
        <FormGroup check className="delete-checkbox">
          <Label>
            <Input
              type="checkbox"
              checked={isDeleteChecked}
              onChange={e => setIsDeleteChecked(e.target.checked)}
              className="larger-checkbox"
            />
            <span className="checkbox-label">
              この商品を倉庫在庫から削除する
            </span>
          </Label>
        </FormGroup>
      </ModalBody>
      <ModalFooter style={{ justifyContent: "center" }}>
        <Button color="secondary" onClick={toggle} style={{ width: "45%" }}>
          キャンセル
        </Button>
        <Button color="primary" onClick={handleUpdate} style={{ width: "45%" }}>
          更新
        </Button>
      </ModalFooter>

      {/* 削除確認モーダル */}
      <Modal isOpen={showDeleteConfirmation} toggle={() => setShowDeleteConfirmation(false)}>
        <ModalHeader toggle={() => setShowDeleteConfirmation(false)}>確認</ModalHeader>
        <ModalBody>
          この商品を在庫から削除しますか？
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => setShowDeleteConfirmation(false)}>
            キャンセル
          </Button>
          <Button color="danger" onClick={handleConfirmDelete}>
            削除
          </Button>
        </ModalFooter>
      </Modal>
    </Modal>
  )
}

export default UpdateItemWarehouseStockModal