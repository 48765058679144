// reducers.js

import {
  FETCH_CAMERA_INFO,
  FETCH_CAMERA_INFO_SUCCESS,
  FETCH_CAMERA_INFO_ERROR,
  UPDATE_CAMERA_INFO,
  UPDATE_CAMERA_INFO_SUCCESS,
  UPDATE_CAMERA_INFO_ERROR,
} from "./actionTypes"

const initialState = {
  cameraInfo: [],
  loading: false,
  error: null,
}

const cameraInfoReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CAMERA_INFO:
    case UPDATE_CAMERA_INFO:
      return { ...state, loading: true }
    case FETCH_CAMERA_INFO_SUCCESS:
    case UPDATE_CAMERA_INFO_SUCCESS:
      return { ...state, loading: false, cameraInfo: action.payload }
    case FETCH_CAMERA_INFO_ERROR:
    case UPDATE_CAMERA_INFO_ERROR:
      return { ...state, loading: false, error: action.payload }
    default:
      return state
  }
}

export default cameraInfoReducer
