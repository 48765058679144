import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Container, Row, Col, Card, CardBody, CardTitle, Table, Modal, ModalHeader, ModalBody, ModalFooter, Button, Form, FormGroup, Label, Input } from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import ErrorMessage from "../Common/ErrorMessage"
import LoadingOverlay from "../Common/LoadingOverlay"
import { fetchCamerasData } from "../../store/cameras/actions"
import { fetchCameraInfo, updateCameraInfo } from "../../store/cameraInfo/actions"

const CameraInfo = () => {
  const dispatch = useDispatch()
  const [searchQuery, setSearchQuery] = useState("")
  const [modal, setModal] = useState(false)
  const [selectedCamera, setSelectedCamera] = useState(null)
  const [formData, setFormData] = useState({})

  const camerasData = useSelector(state => state.camerasReducer.camerasData)
  const isLoading = useSelector(state => state.camerasReducer.loading)
  const cameraInfo = useSelector(state => state.cameraInfo.cameraInfo)
  const isCameraInfoLoading = useSelector(state => state.cameraInfo.loading)
  
  const cameras = camerasData && camerasData.data ? camerasData.data.results : []

  useEffect(() => {
    dispatch(fetchCamerasData())
  }, [dispatch])

  useEffect(() => {
    if (cameraInfo) {
      setFormData(cameraInfo)
    }
  }, [cameraInfo])

  const handleSearch = event => {
    setSearchQuery(event.target.value)
  }

  const filteredCameras = cameras.filter(
    camera =>
      camera.uuid.toLowerCase().includes(searchQuery.toLowerCase()) ||
      camera.loc_name.toLowerCase().includes(searchQuery.toLowerCase())
  )

  const handleCameraClick = (camera) => {
    setSelectedCamera(camera)
    dispatch(fetchCameraInfo(camera.uuid))
    setModal(true)
  }

  const toggle = () => setModal(!modal)

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    dispatch(updateCameraInfo(formData))  // formData には uuid が含まれているので、そのまま渡します
    toggle()
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            maintitle="main"
            title="管理"
            breadcrumbItem="Prime-eye状態管理"
          />
          <ErrorMessage />
          <LoadingOverlay isLoading={isLoading} />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <CardTitle className="h4">カメラ一覧</CardTitle>
                  <div className="mb-3">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="UUIDまたはロケーション名で検索"
                      value={searchQuery}
                      onChange={handleSearch}
                    />
                  </div>
                  <Table className="table table-striped table-bordered mb-0">
                    <thead>
                      <tr>
                        <th>No.</th>
                        <th>カメラID</th>
                        <th>状態</th>
                        <th>UUID</th>
                        <th>受信開始</th>
                        <th>受信終了</th>
                        <th>ロケーション名</th>
                        <th>開始日時</th>
                        <th>終了日時</th>
                        <th>備考欄</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredCameras.map((camera, index) => (
                        <tr key={`${camera.camera_id}-${index}`} onClick={() => handleCameraClick(camera)} style={{cursor: 'pointer'}}>
                          <td>{index + 1}</td>
                          <td>{camera.camera_id}</td>
                          <td>{camera.status}</td>
                          <td>{camera.uuid}</td>
                          <td>{camera.receive_start}</td>
                          <td>{camera.receive_end}</td>
                          <td>{camera.loc_name}</td>
                          <td>{camera.start_at}</td>
                          <td>{camera.end_at}</td>
                          <td>{camera.note}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      <Modal isOpen={modal} toggle={toggle} size="lg">
        <Form onSubmit={handleSubmit}>
          <ModalHeader toggle={toggle}>カメラ詳細情報</ModalHeader>
          <ModalBody>
            {isCameraInfoLoading ? (
              <p>読み込み中...</p>
            ) : cameraInfo ? (
              <Row>
                <Col md={12}>
                  <FormGroup>
                    <Label for="uuid"><strong>UUID:</strong> {formData.uuid || ''}</Label>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label for="device_no">デバイス番号</Label>
                    <Input type="text" name="device_no" id="device_no" value={formData.device_no || ''} onChange={handleInputChange} />
                  </FormGroup>
                  <FormGroup>
                    <Label for="hostname">ホスト名</Label>
                    <Input type="text" name="hostname" id="hostname" value={formData.hostname || ''} onChange={handleInputChange} />
                  </FormGroup>
                  <FormGroup>
                    <Label for="ipc_serial_no">IPC シリアル番号</Label>
                    <Input type="text" name="ipc_serial_no" id="ipc_serial_no" value={formData.ipc_serial_no || ''} onChange={handleInputChange} />
                  </FormGroup>
                  <FormGroup>
                    <Label for="sim_no">SIM 番号</Label>
                    <Input type="text" name="sim_no" id="sim_no" value={formData.sim_no || ''} onChange={handleInputChange} />
                  </FormGroup>
                  <FormGroup>
                    <Label for="icc_id">ICC ID</Label>
                    <Input type="text" name="icc_id" id="icc_id" value={formData.icc_id || ''} onChange={handleInputChange} />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label for="allxon_sn">Allxon SN</Label>
                    <Input type="text" name="allxon_sn" id="allxon_sn" value={formData.allxon_sn || ''} onChange={handleInputChange} />
                  </FormGroup>
                  <FormGroup>
                    <Label for="ipc_owner">IPC オーナー</Label>
                    <Input type="text" name="ipc_owner" id="ipc_owner" value={formData.ipc_owner || ''} onChange={handleInputChange} />
                  </FormGroup>
                  <FormGroup>
                    <Label for="camera_owner">カメラオーナー</Label>
                    <Input type="text" name="camera_owner" id="camera_owner" value={formData.camera_owner || ''} onChange={handleInputChange} />
                  </FormGroup>
                  <FormGroup>
                    <Label for="camera_operator">カメラオペレーター</Label>
                    <Input type="text" name="camera_operator" id="camera_operator" value={formData.camera_operator || ''} onChange={handleInputChange} />
                  </FormGroup>
                  <FormGroup>
                    <Label for="ip_addr">IP アドレス</Label>
                    <Input type="text" name="ip_addr" id="ip_addr" value={formData.ip_addr || ''} onChange={handleInputChange} />
                  </FormGroup>
                </Col>
              </Row>
            ) : (
              <p>情報が見つかりません</p>
            )}
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={toggle}>キャンセル</Button>
            <Button color="primary" type="submit">更新</Button>
          </ModalFooter>
        </Form>
      </Modal>
    </React.Fragment>
  )
}

export default CameraInfo