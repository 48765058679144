import React, { useState, useEffect } from "react"
import { useDispatch } from "react-redux"
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  FormGroup,
  Label,
  Input,
} from "reactstrap"
import {
  updateWarehouseStock,
  deleteWarehouseStock,
} from "../../../store/warehouse/actions"

const UpdateItemWarehouseStockModal = ({
  isOpen,
  toggle,
  item,
  onUpdate,
  warehouses,
  selectedWarehouseId,
}) => {
  const dispatch = useDispatch()

  const [updatedItem, setUpdatedItem] = useState({
    warehouse_stock_id: "",
    warehouse_id: "",
    goods_id: "",
    stock_count: 0,
  })

  const [itemDetails, setItemDetails] = useState({
    goods_no: "",
    goods_name: "",
  })

  useEffect(() => {
    if (item) {
      setUpdatedItem({
        warehouse_stock_id: item.warehouse_stock_id || "",
        warehouse_id: item.warehouse_id || selectedWarehouseId || "",
        goods_id: item.goods_id || "",
        stock_count: item.stock_count || 0,
      })
      setItemDetails({
        goods_no: item.goods_no || "",
        goods_name: item.goods_name || "",
      })
    }
  }, [item, selectedWarehouseId])

  const handleChange = e => {
    const { name, value } = e.target
    setUpdatedItem({
      ...updatedItem,
      [name]: name === "stock_count" ? Number(value) : value,
    })
  }

  const handleUpdate = () => {
    dispatch(updateWarehouseStock(updatedItem))
    toggle()
  }

  const handleDelete = () => {
    if (window.confirm("本当にこの商品を削除しますか？")) {
      dispatch(
        deleteWarehouseStock(
          updatedItem.warehouse_stock_id,
          updatedItem.warehouse_id
        )
      )
      toggle()
    }
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>倉庫在庫商品情報を更新</ModalHeader>
      <ModalBody>
        <FormGroup>
          <Label htmlFor="warehouse_id">倉庫ID</Label>
          <Input
            id="warehouse_id"
            name="warehouse_id"
            type="select"
            value={updatedItem.warehouse_id}
            onChange={handleChange}
          >
            <option value="">倉庫を選択してください</option>
            {warehouses.map(warehouse => (
              <option
                key={warehouse.warehouse_id}
                value={warehouse.warehouse_id}
              >
                {warehouse.warehouse_code} - {warehouse.address}
              </option>
            ))}
          </Input>
        </FormGroup>
        <FormGroup>
          <Label htmlFor="goods_no">商品コード</Label>
          <Input
            id="goods_no"
            name="goods_no"
            type="text"
            value={itemDetails.goods_no}
            disabled
            style={{ backgroundColor: "#e9ecef" }}
          />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="goods_name">商品名</Label>
          <Input
            id="goods_name"
            name="goods_name"
            type="text"
            value={itemDetails.goods_name}
            disabled
            style={{ backgroundColor: "#e9ecef" }}
          />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="stock_count">数量</Label>
          <Input
            id="stock_count"
            name="stock_count"
            type="number"
            value={updatedItem.stock_count}
            onChange={handleChange}
          />
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>
          キャンセル
        </Button>
        <Button color="danger" onClick={handleDelete}>
          削除
        </Button>
        <Button color="primary" onClick={handleUpdate}>
          更新
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default UpdateItemWarehouseStockModal
