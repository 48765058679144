import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  FormGroup,
  Label,
  Input,
  ModalFooter,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import ErrorMessage from "../Common/ErrorMessage"
import LoadingOverlay from "../Common/LoadingOverlay"
import {
  fetchCompanies,
  registerCompany,
  updateCompany,
  deleteCompany,
} from "../../store/companies/actions"

const DeleteConfirmModal = ({ isOpen, onConfirm, onCancel, companyName }) => (
  <Modal isOpen={isOpen}>
    <ModalHeader>削除確認</ModalHeader>
    <ModalBody>
      {companyName ? (
        <>
          <p>以下の企業を削除してもよろしいですか？</p>
          企業名：<strong style={{ fontSize: "1.5em" }}>{companyName}</strong>
        </>
      ) : (
        <p>企業を削除してもよろしいですか？</p>
      )}
    </ModalBody>
    <ModalFooter>
      <Button color="danger" onClick={onConfirm}>
        はい
      </Button>{" "}
      <Button color="secondary" onClick={onCancel}>
        いいえ
      </Button>
    </ModalFooter>
  </Modal>
)

const AddCompanyModal = ({ isOpen, onClose, onAdd }) => {
  const dispatch = useDispatch()

  const [newCompanyName, setNewCompanyName] = useState("")
  const [newCompanyComment, setNewCompanyComment] = useState("")
  const [newCompanyLevel, setNewCompanyLevel] = useState("0") // Default to 0
  const [missingRequiredFields, setMissingRequiredFields] = useState(false)

  const handleAdd = () => {
    if (!newCompanyName || !newCompanyComment) {
      setMissingRequiredFields(true)
      return
    }
    setMissingRequiredFields(false)
    const newCompany = {
      name: newCompanyName,
      comment: newCompanyComment,
      level: newCompanyLevel, // Include level
    }

    dispatch(registerCompany(newCompany))
    setNewCompanyName("")
    setNewCompanyComment("")
    setNewCompanyLevel("0")
    onClose()
  }

  return (
    <Modal isOpen={isOpen} toggle={onClose}>
      <ModalHeader toggle={onClose}>新規企業登録</ModalHeader>
      <ModalBody>
        <FormGroup>
          <Label htmlFor="companyName">企業名</Label>
          <Input
            type="text"
            id="companyName"
            value={newCompanyName}
            onChange={e => setNewCompanyName(e.target.value)}
          />
          <Label htmlFor="companyComment">コメント</Label>
          <Input
            type="text"
            id="companyComment"
            value={newCompanyComment}
            onChange={e => setNewCompanyComment(e.target.value)}
          />
          <Label htmlFor="companyLevel">レベル</Label>
          <Input
            type="select"
            id="companyLevel"
            value={newCompanyLevel}
            onChange={e => setNewCompanyLevel(e.target.value)}
          >
            <option value="0">0</option>
            <option value="1">1</option>
          </Input>
          {missingRequiredFields && (
            <p style={{ color: "red" }}>全てのフィールドを入力してください。</p>
          )}
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" className="w-25" onClick={onClose}>
          キャンセル
        </Button>
        <Button color="primary" className="w-25" onClick={handleAdd}>
          追加
        </Button>
      </ModalFooter>
    </Modal>
  )
}

const Company = () => {
  const dispatch = useDispatch()
  const companiesData = useSelector(state => state.companyReducer.companiesData)
  const isLoading = useSelector(state => state.companyReducer.loading)

  const [selectedCompany, setSelectedCompany] = useState(null)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isDeleteConfirmModalOpen, setIsDeleteConfirmModalOpen] =
    useState(false)
  const [isAddModalOpen, setIsAddModalOpen] = useState(false)
  const [editCompanyName, setEditCompanyName] = useState("")
  const [editCompanyComment, setEditCompanyComment] = useState("")
  const [editCompanyLevel, setEditCompanyLevel] = useState("0") // Default to 0

  const handleAddCompanyButtonClick = () => {
    setIsModalOpen(false)
    setIsAddModalOpen(true)
  }

  const handleDeleteClick = () => {
    setIsDeleteConfirmModalOpen(true)
  }

  const handleConfirmDelete = () => {
    if (selectedCompany) {
      dispatch(deleteCompany(selectedCompany.id))
    }
    setIsDeleteConfirmModalOpen(false)
    setIsModalOpen(false)
  }

  const handleCancelDelete = () => {
    setIsDeleteConfirmModalOpen(false)
  }

  useEffect(() => {
    dispatch(fetchCompanies())
  }, [dispatch])

  const handleCompanyClick = company => {
    setIsAddModalOpen(false)
    setSelectedCompany(company)
    setEditCompanyName(company.name)
    setEditCompanyComment(company.comment)
    setEditCompanyLevel(company.level?.toString() || "0")
    setIsModalOpen(true)
  }

  const handleUpdateCompany = () => {
    if (!selectedCompany) {
      return
    }
    const updatedCompany = {
      ...selectedCompany,
      name: editCompanyName,
      comment: editCompanyComment,
      level: editCompanyLevel,
    }

    dispatch(updateCompany(selectedCompany.id, updatedCompany))
    setIsModalOpen(false)
  }

  const handleAddCompany = newCompany => {
    dispatch(registerCompany(newCompany))
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            maintitle="main"
            title="管理"
            breadcrumbItem="企業一覧"
          />
          <ErrorMessage />
          <Row>
            <Col xs={12}>
              <Card>
                <CardBody>
                  <CardTitle>企業一覧</CardTitle>
                  <Button
                    color="primary"
                    className={
                      window.innerWidth < 500 ? "mb-3 w-100" : "mb-3 w-25"
                    }
                    onClick={handleAddCompanyButtonClick}
                  >
                    新規企業登録
                  </Button>
                  <table className="table">
                    <thead>
                      <tr>
                        <th style={{ width: "10%" }}>企業ID</th>
                        <th style={{ width: "50%" }}>企業名</th>
                        <th style={{ width: "40%" }}>コメント</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Array.isArray(companiesData) &&
                      companiesData.length > 0 ? (
                        companiesData.map(company => (
                          <tr
                            key={company.id}
                            onClick={() => handleCompanyClick(company)}
                          >
                            <td>{company.id}</td>
                            <td>{company.name}</td>
                            <td>{company.comment}</td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="3">企業データがありません。</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <Modal isOpen={isModalOpen} toggle={() => setIsModalOpen(false)}>
        <ModalHeader toggle={() => setIsModalOpen(false)}>企業詳細</ModalHeader>
        <ModalBody>
          <LoadingOverlay isLoading={isLoading} />
          {selectedCompany && (
            <>
              <FormGroup>
                <Label htmlFor="companyName">企業名</Label>
                <Input
                  type="text"
                  id="companyName"
                  value={editCompanyName}
                  onChange={e => setEditCompanyName(e.target.value)}
                />
              </FormGroup>
              <FormGroup>
                <Label htmlFor="companyComment">コメント</Label>
                <Input
                  type="text"
                  id="companyComment"
                  value={editCompanyComment}
                  onChange={e => setEditCompanyComment(e.target.value)}
                />
              </FormGroup>
              <FormGroup>
                <Label htmlFor="companyLevel">レベル</Label>
                <Input
                  type="select"
                  id="companyLevel"
                  value={editCompanyLevel}
                  onChange={e => setEditCompanyLevel(e.target.value)}
                >
                  <option value="0">0</option>
                  <option value="1">1</option>
                </Input>
              </FormGroup>
              <Button color="danger" onClick={handleDeleteClick}>
                削除
              </Button>
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => setIsModalOpen(false)}>
            キャンセル
          </Button>
          {selectedCompany ? (
            <Button
              color="primary"
              className="w-25"
              onClick={handleUpdateCompany}
            >
              更新
            </Button>
          ) : (
            <Button color="primary" className="w-25" onClick={handleAddCompany}>
              追加
            </Button>
          )}
        </ModalFooter>
      </Modal>
      <DeleteConfirmModal
        isOpen={isDeleteConfirmModalOpen}
        onConfirm={handleConfirmDelete}
        onCancel={handleCancelDelete}
        companyName={selectedCompany ? selectedCompany.name : ""}
      />
      <AddCompanyModal
        isOpen={isAddModalOpen}
        onClose={() => setIsAddModalOpen(false)}
        onAdd={handleAddCompany}
      />
      <LoadingOverlay active={isLoading} />
    </React.Fragment>
  )
}

export default Company
