import {
  FETCH_LINE_USERS,
  FETCH_LINE_USERS_SUCCESS,
  FETCH_LINE_USERS_FAIL,
  POST_LINK_USER,
  POST_LINK_USER_SUCCESS,
  POST_LINK_USER_FAIL,
  DELETE_LINE_USER,
  DELETE_LINE_USER_SUCCESS,
  DELETE_LINE_USER_FAIL,
  PUT_LINE_USER,
  PUT_LINE_USER_SUCCESS,
  PUT_LINE_USER_FAIL,
  PUT_LINK_USER,
  PUT_LINK_USER_SUCCESS,
  PUT_LINK_USER_FAIL,
} from "./actionTypes"

export const fetchLineUsers = (source = "default") => ({
  type: FETCH_LINE_USERS,
  payload: { source },
})

export const fetchLineUsersSuccess = users => ({
  type: FETCH_LINE_USERS_SUCCESS,
  payload: users,
})

export const fetchLineUsersFail = error => ({
  type: FETCH_LINE_USERS_FAIL,
  payload: error,
})

export const postLineUser = (lineUserId, appUserId) => ({
  type: POST_LINK_USER,
  payload: { lineUserId, appUserId },
})

export const postLineUserSuccess = updatedUser => ({
  type: POST_LINK_USER_SUCCESS,
  payload: updatedUser,
})

export const postLineUserFail = error => ({
  type: POST_LINK_USER_FAIL,
  payload: error,
})

export const deleteLineUser = lineUserId => ({
  type: DELETE_LINE_USER,
  payload: lineUserId,
})

export const deleteLineUserSuccess = deletedUserId => ({
  type: DELETE_LINE_USER_SUCCESS,
  payload: deletedUserId,
})

export const deleteLineUserFail = error => ({
  type: DELETE_LINE_USER_FAIL,
  payload: error,
})

export const putLineUser = lineUserId => ({
  type: PUT_LINK_USER,
  payload: lineUserId,
})

export const putLineUserSuccess = updatedUser => ({
  type: PUT_LINK_USER_SUCCESS,
  payload: updatedUser,
})

export const putLineUserFail = error => ({
  type: PUT_LINK_USER_FAIL,
  payload: error,
})
