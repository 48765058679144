import React, { useState, useMemo, useEffect } from "react"
import { useDispatch } from "react-redux"
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Input,
  FormGroup,
  Label,
} from "reactstrap"
import Select from "react-select"
import { addWarehouseStock } from "../../../store/warehouse/actions"
import "./QuantityInput.css"

import { FaChevronCircleDown, FaChevronCircleUp } from "react-icons/fa"

const AddItemWarehouseStockModal = ({
  isOpen,
  toggle,
  goods,
  selectedWarehouseId,
}) => {
  const [selectedGood, setSelectedGood] = useState(null)
  const [quantities, setQuantities] = useState([0, 0, 0])
  const [searchQuery, setSearchQuery] = useState("")
  const [selectedBrand, setSelectedBrand] = useState(null)
  const [sortConfig, setSortConfig] = useState({
    key: "goods_id",
    direction: "ascending",
  })

  const dispatch = useDispatch()

  // ブランドリストの作成を修正
  const brandOptions = useMemo(() => {
    const uniqueBrands = [...new Set(goods.map(good => good.goods_brand_name).filter(Boolean))]
    return [
      { value: "", label: "ブランド名なし" },
      ...uniqueBrands.map(brand => ({ value: brand, label: brand }))
    ]
  }, [goods])

  const handleAddItem = () => {
    if (!selectedGood) return

    const stockCount = quantities[0] * 100 + quantities[1] * 10 + quantities[2]

    const newItem = {
      warehouse_id: selectedWarehouseId,
      goods_id: selectedGood.goods_id,
      stock_count: stockCount,
    }

    dispatch(addWarehouseStock(newItem))

    setSelectedGood(null)
    setQuantities([0, 0, 0])
    setSelectedBrand(null)

    toggle()
  }

  const handleSort = key => {
    let direction = "ascending"
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending"
    }
    setSortConfig({ key, direction })
  }

  const sortedGoods = useMemo(() => {
    let sortableGoods = [...goods]
    sortableGoods.sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === "ascending" ? -1 : 1
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === "ascending" ? 1 : -1
      }
      return 0
    })
    return sortableGoods
  }, [goods, sortConfig])

  // フィルタリングされた商品リストを作成
  const filteredGoods = useMemo(() => {
    const uniqueGoodsMap = new Map();
    
    sortedGoods.forEach(good => {
      const lowercaseQuery = searchQuery.toLowerCase()
      const matchesSearch = (
        (good.goods_name && good.goods_name.toLowerCase().includes(lowercaseQuery)) ||
        (good.company_name && good.company_name.toLowerCase().includes(lowercaseQuery)) ||
        (good.goods_no && good.goods_no.toLowerCase().includes(lowercaseQuery))
      )
      const matchesBrand = !selectedBrand || 
        (selectedBrand.value === "" && !good.goods_brand_name) || 
        good.goods_brand_name === selectedBrand.value
      const isNotSurprise = good.surprise_goods_id === null  // surprise_goods_id が null の商品のみを表示

      if (matchesSearch && matchesBrand && isNotSurprise) {
        uniqueGoodsMap.set(good.goods_id, good);
      }
    });

    return Array.from(uniqueGoodsMap.values());
  }, [sortedGoods, searchQuery, selectedBrand])

  const handleRowClick = good => {
    setSelectedGood(good)
  }

  const handleQuantityChange = (index, increment) => {
    setQuantities(prevQuantities => {
      const newQuantities = [...prevQuantities]
      newQuantities[index] = Math.min(
        9,
        Math.max(0, newQuantities[index] + increment)
      )
      return newQuantities
    })
  }

  const handleResetQuantities = () => {
    setQuantities([0, 0, 0])
  }

  // 商品表示用の関数を追加
  const formatGoodDisplay = (good) => {
    const brandDisplay = good.goods_brand_name
      ? ` (ブランド名：${good.goods_brand_name})`
      : '';
    return `${good.goods_no}：${good.goods_name}${brandDisplay}`;
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>倉庫在庫に商品を追加</ModalHeader>
      <ModalBody>
        <FormGroup>
          <Label htmlFor="brandSelect">ブランド</Label>
          <Select
            id="brandSelect"
            options={brandOptions}
            value={selectedBrand}
            onChange={setSelectedBrand}
            isClearable
            placeholder="ブランドを選択"
          />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="search">商品検索</Label>
          <Input
            id="search"
            type="text"
            placeholder="商品名または会社名、または商品番号で検索"
            value={searchQuery}
            onChange={e => setSearchQuery(e.target.value)}
          />
        </FormGroup>
        <div style={{ maxHeight: "300px", overflowY: "scroll" }}>
          <ul style={{ padding: 0, listStyleType: "none" }}>
            {filteredGoods.map(good => (
              <li
                key={good.goods_id}
                onClick={() => handleRowClick(good)}
                style={{
                  cursor: "pointer",
                  padding: "10px",
                  backgroundColor:
                    selectedGood && selectedGood.goods_id === good.goods_id
                      ? "#d3d3d3"
                      : "transparent",
                  borderBottom: "1px solid #ccc",
                }}
              >
                {formatGoodDisplay(good)}
              </li>
            ))}
          </ul>
        </div>
        <FormGroup>
          <Label>数量</Label>
          <div className="quantity-inputs">
            <button
              type="button"
              className="reset-button"
              onClick={handleResetQuantities}
            >
              ×
            </button>
            {quantities.map((quantity, index) => (
              <div key={index} className="quantity-input">
                <button
                  type="button"
                  className="arrow up"
                  onClick={() => handleQuantityChange(index, 1)}
                >
                  <FaChevronCircleUp style={{ color: "blue" }} />
                </button>
                <input
                  type="number"
                  value={quantity}
                  readOnly
                  className="quantity-number"
                />
                <button
                  type="button"
                  className="arrow down"
                  onClick={() => handleQuantityChange(index, -1)}
                >
                  <FaChevronCircleDown style={{ color: "red" }} />
                </button>
              </div>
            ))}
            <span className="unit-label">個</span>
          </div>
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>
          キャンセル
        </Button>
        <Button color="primary" onClick={handleAddItem}>
          追加
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default AddItemWarehouseStockModal