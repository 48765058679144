import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"

// Authentication
import Login from "./auth/login/reducer"
import Account from "./auth/register/reducer"
import ForgetPassword from "./auth/forgetpwd/reducer"
import Profile from "./auth/profile/reducer"

//Calendar
import calendar from "./calendar/reducer"

//Flows
import flowsReducer from "./flows/reducer"

// import marketing
import marketingCommonReducer from "./marketing/common/reducer"
import marketingVolumeReducer from "./marketing/volume/reducer"
import marketingCustomerReducer from "./marketing/customer/reducer"
import marketingTimeReducer from "./marketing/time/reducer"
import BrandsReducer from "./brands/reducer"
import usersReducer from "./users/reducer"
import camerasReducer from "./cameras/reducer"
import stocksReducer from "./stocks/reducer"
import salesDailyReducer from "./salesDaily/reducer"
import salesReducer from "./sales/reducer"
import salesAllReducer from "./salesAll/reducer"
import salesLocReducer from "./salesLoc/reducer"
import customersReducer from "./customers/reducer"
import announcementReducer from "./announce/reducer"
import companyReducer from "./companies/reducer"
import shelfsReducer from "./shelfs/reducer"
import locationReducer from "./location/reducer"
import locationGroupsReducer from "./locationGroup/reducer"
import soldOutReducer from "./soldout/reducer"
import profileReducer from "./auth/profile/reducer"
import warehouseReducer from "./warehouse/reducer"
import GoodsReducer from "./goods/reducer"
import vehicleReducer from "./vehicle/reducer"
import deliveryPlanReducer from "./deliveryPlan/reducer"
import lineUserReducer from "./lineUser/reducer" // 新しく追加

import { PUT_ERROR, CLEAR_ERROR } from "./actions"

function commonReducer(state = {}, action) {
  switch (action.type) {
    case PUT_ERROR:
      return {
        ...state,
        error: action.payload,
      }
    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
      }
    default:
      return state
  }
}

import cameraInfoReducer from "./cameraInfo/reducer"

const rootReducer = combineReducers({
  // common
  common: commonReducer,
  // public
  Layout,
  Login,
  Account,
  ForgetPassword,
  profileReducer,
  calendar,
  flowsReducer,
  marketingCommonReducer,
  marketingVolumeReducer,
  marketingCustomerReducer,
  marketingTimeReducer,
  BrandsReducer,
  usersReducer,
  camerasReducer,
  stocksReducer,
  salesDailyReducer,
  salesReducer,
  salesAllReducer,
  salesLocReducer,
  customersReducer,
  announcementReducer,
  companyReducer,
  shelfsReducer,
  locationReducer,
  locationGroupsReducer,
  soldOutReducer,
  warehouseReducer,
  GoodsReducer,
  vehicleReducer,
  deliveryPlanReducer,
  lineUserReducer, // 新しく追加
  cameraInfo: cameraInfoReducer,
})

export default rootReducer
