import React, { useState, useEffect, useMemo } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Label,
  Input,
  ModalFooter,
  Badge,
} from "reactstrap"
import Select from "react-select"
import { ListGroup, ListGroupItem } from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useDispatch, useSelector } from "react-redux"
import { CardTitle } from "reactstrap"
import {
  fetchGraphData,
  fetchGoodsBrandRegData,
} from "../../store/brands/actions"
import { fetchGoodsBrandData } from "../../store/marketing/common/actions"
import { updateGoods } from "../../store/brands/actions"

const GoodList = () => {
  const [newGood, setNewGood] = useState({ name: "", brand: null })
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false)
  const [selectedGoods, setSelectedGoods] = useState({})
  const [selectedBrand, setSelectedBrand] = useState(null)
  const [searchCriteria, setSearchCriteria] = useState("")
  const [editingProduct, setEditingProduct] = useState(null)
  const [editingBrand, setEditingBrand] = useState(null)
  const [editedBrandName, setEditedBrandName] = useState("")
  const [newBrand, setNewBrand] = useState("")
  const [editingProductId, setEditingProductId] = useState(null)
  const [tempSelectedBrand, setTempSelectedBrand] = useState(null)
  const [selectedRows, setSelectedRows] = useState({})
  const [deletingBrand, setDeletingBrand] = useState(null)
  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] =
    useState(false)

  const dispatch = useDispatch()

  const graphData = useSelector(state => state.BrandsReducer.graphData)
  const goods = graphData && graphData.data ? graphData.data.goodsResults : []
  const goodsBrandData =
    graphData && graphData.data ? graphData.data.goodsBrandResults : []

  // TODO バックエンドで商品と商品ブランド両方返す、値を分けて、BrandsReducerに格納する

  const isLoading = useSelector(state => state.BrandsReducer.loading)

  let filteredGoods = goods.filter(good =>
    good && searchCriteria
      ? (good.goods_name ? good.goods_name.includes(searchCriteria) : false) ||
        (good.goods_brand_id
          ? good.goods_brand_id.toString().includes(searchCriteria)
          : false)
      : true
  )

  const goodsBrandOptions = useMemo(() => {
    if (!goodsBrandData) return []

    return [
      ...goodsBrandData.map(item => ({
        label: item.goods_brand_name,
        value: item.goods_brand_id,
      })),
    ]
  }, [goodsBrandData])

  const handleNewGoodChange = selectedOption => {
    setNewGood({
      ...newGood,
      name: selectedOption.label,
      brand: selectedOption.value,
    })
  }

  const handleBrandRegistration = () => {
    // Open the confirmation modal
    console.log("Registering brand: ", newBrand)

    // 対象商品ID配列、対象ブランドID,ブランド名、削除フラグ、登録フラグ
    dispatch(fetchGoodsBrandRegData(null, null, newBrand, null, true))
  }

  const handleBrandAllRegistration = () => {
    const selectedGoodsIds = Object.keys(selectedGoods).filter(
      id => selectedGoods[id]
    )
    if (selectedGoodsIds.length === 0) {
      alert("商品が選択されていません。")
      return
    }
    if (!newGood.brand) {
      alert("ブランドが選択されていません。")
      return
    }
    setIsConfirmationModalOpen(true)
  }

  const handleBrandChange = selectedOption => {
    if (editingProductId) {
      setTempSelectedBrand(selectedOption)
    } else {
      setSelectedBrand(selectedOption)
      if (editingProduct) {
        let updatedProduct = {
          ...editingProduct,
          goods_brand_id: selectedOption.value,
          goods_brand_name: selectedOption.label,
        }
        setEditingProduct(updatedProduct)

        // 対象商品ID配列、対象ブランドID,ブランド名、削除フラグ、登録フラグ
        dispatch(
          fetchGoodsBrandRegData(
            [updatedProduct.goods_id],
            updatedProduct.goods_brand_id,
            null,
            null,
            null
          )
        )
      }
    }
  }

  const selectAllGoods = () => {
    const newSelectedGoods = {}
    filteredGoods.forEach(good => {
      newSelectedGoods[good.goods_id] = true
    })
    setSelectedGoods(newSelectedGoods)
  }

  const selectFilteredGoods = () => {
    const newSelectedGoods = {}
    filteredGoods.forEach(good => {
      newSelectedGoods[good.goods_id] = true
    })
    setSelectedGoods(newSelectedGoods)
  }

  const deselectAllGoods = () => {
    setSelectedGoods({})
  }

  useEffect(() => {
    // dispatch(fetchGoodsBrandData());
    dispatch(fetchGraphData())
  }, [dispatch])

  const handleSearchCriteriaChange = e => {
    setSearchCriteria(e.target.value)
    selectFilteredGoods()
  }

  const handleSelectProduct = product => {
    if (editingProductId === product.goods_id) {
      // キャンセル処理
      setEditingProductId(null)
      setTempSelectedBrand(null)
    } else {
      // 編集モード開始
      setEditingProductId(product.goods_id)
      setTempSelectedBrand(
        goodsBrandOptions.find(
          option => option.value === product.goods_brand_id
        ) || null
      )
    }
  }

  const handleSaveBrandChange = productId => {
    const updatedGoods = goods.map(good =>
      good.goods_id === productId
        ? {
            ...good,
            goods_brand_id: tempSelectedBrand.value,
            goods_brand_name: tempSelectedBrand.label,
          }
        : good
    )
    dispatch(updateGoods(updatedGoods))
    dispatch(
      fetchGoodsBrandRegData(
        [productId],
        tempSelectedBrand.value,
        null,
        null,
        null
      )
    )
    setEditingProductId(null)
    setTempSelectedBrand(null)
  }

  useEffect(() => {
    if (selectedBrand) {
      setEditingProduct(null)
    }
  }, [selectedBrand])

  const confirmBrandChange = () => {
    const targetGoods = Object.keys(selectedGoods).filter(
      goodId => selectedGoods[goodId]
    )
    dispatch(
      fetchGoodsBrandRegData(targetGoods, newGood.brand, null, null, null)
    )
    setIsConfirmationModalOpen(false)
    setSelectedGoods({}) // 選択をリセット
  }

  const handleBrandDeleteClick = brand => {
    setDeletingBrand(brand)
    setIsDeleteConfirmationModalOpen(true)
  }

  const confirmBrandDelete = () => {
    if (deletingBrand) {
      dispatch(
        fetchGoodsBrandRegData([], deletingBrand.value, null, true, null)
      )
    }
    setIsDeleteConfirmationModalOpen(false)
    setDeletingBrand(null)
  }

  const handleBrandEditStart = brand => {
    setEditingBrand(brand)
    setEditedBrandName(brand.label)
  }

  const handleBrandEditChange = event => {
    setEditedBrandName(event.target.value)
  }

  const handleBrandEditCommit = () => {
    //　ブランド名変更処理

    // 対象商品ID配列、対象ブランドID,ブランド名、削除フラグ、登録フラグ
    dispatch(
      fetchGoodsBrandRegData(
        null,
        editingBrand.value,
        editedBrandName,
        null,
        null
      )
    )

    setEditingBrand(null)
  }

  const handleNewBrandChange = event => {
    setNewBrand(event.target.value)
  }

  const toggleRowSelection = productId => {
    setSelectedGoods(prev => ({
      ...prev,
      [productId]: !prev[productId],
    }))
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            maintitle="main"
            title="管理"
            breadcrumbItem="商品ブランド管理"
          />
          <Row>
            <Col xs={12}>
              <Card className="bg-secondary">
                <CardBody>
                  {isLoading && (
                    <div
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                        zIndex: 9999, // 必要に応じて、他の要素より前面に表示されるように設定します
                      }}
                    >
                      <div style={{ color: "white", fontSize: "2em" }}>
                        Loading...
                      </div>
                    </div>
                  )}
                  <h4 className="card-title mb-4">一括ブランド登録</h4>
                  <p>
                    選択したブランドで、チェックボックスが押されている商品のブランド登録を行います。
                  </p>
                  <Select
                    className="form-control my-3"
                    options={goodsBrandOptions}
                    onChange={handleNewGoodChange}
                  />
                  <Button
                    color="primary"
                    onClick={handleBrandAllRegistration}
                    style={{ float: "right" }}
                  >
                    登録
                  </Button>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Card>
                <CardBody>
                  <CardTitle className="d-flex justify-content-between align-items-center mb-4">
                    <h4 className="mb-0">商材一覧</h4>
                    <Button
                      color="primary"
                      onClick={() => setIsModalOpen(true)}
                    >
                      <i className="bx bx-plus me-1"></i>ブランド管理
                    </Button>
                  </CardTitle>
                  <div className="mb-3">
                    <Input
                      type="text"
                      onChange={handleSearchCriteriaChange}
                      placeholder="商品名または商品番号で検索..."
                      className="form-control-lg"
                    />
                  </div>
                  <div className="table-responsive">
                    <table className="table table-borderless table-hover">
                      <thead className="table-light">
                        <tr>
                          <th className="text-muted" style={{ width: "50px" }}>
                            選択
                          </th>
                          <th className="text-muted" style={{ width: "100px" }}>
                            商品番号
                          </th>
                          <th className="text-muted" style={{ width: "40%" }}>
                            商品名
                          </th>
                          <th className="text-muted" style={{ width: "20%" }}>
                            ブランド
                          </th>
                          <th className="text-muted" style={{ width: "60px" }}>
                            アクション
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {filteredGoods.map(product => (
                          <tr
                            key={product.goods_id}
                            onClick={() => toggleRowSelection(product.goods_id)}
                            className={
                              selectedGoods[product.goods_id]
                                ? "selected-row"
                                : ""
                            }
                            style={{ cursor: "pointer" }}
                          >
                            <td>
                              <input
                                type="checkbox"
                                checked={!!selectedGoods[product.goods_id]}
                                onChange={() => {}} // チェックボックスのデフォルト動作を防ぐ
                                onClick={e => e.stopPropagation()} // クリックイベントの伝播を止める
                              />
                            </td>
                            <td>{product.goods_no}</td>
                            <td>{product.goods_name}</td>
                            <td>
                              {editingProductId === product.goods_id ? (
                                <>
                                  <Select
                                    options={goodsBrandOptions}
                                    onChange={handleBrandChange}
                                    value={tempSelectedBrand}
                                    className="react-select"
                                    classNamePrefix="select"
                                  />
                                  <Button
                                    color="success"
                                    size="sm"
                                    onClick={e => {
                                      e.stopPropagation()
                                      handleSaveBrandChange(product.goods_id)
                                    }}
                                    className="mt-2 me-2"
                                  >
                                    保存
                                  </Button>
                                </>
                              ) : product.goods_brand_id ? (
                                product.goods_brand_name ||
                                (
                                  goodsBrandOptions.find(
                                    brand =>
                                      brand.value === product.goods_brand_id
                                  ) || {}
                                ).label ||
                                "未分類"
                              ) : (
                                "未分類"
                              )}
                            </td>
                            <td>
                              <Button
                                color={
                                  editingProductId === product.goods_id
                                    ? "secondary"
                                    : "info"
                                }
                                size="sm"
                                onClick={e => {
                                  e.stopPropagation()
                                  handleSelectProduct(product)
                                }}
                              >
                                <i
                                  className={`bx ${
                                    editingProductId === product.goods_id
                                      ? "bx-x"
                                      : "bx-edit-alt"
                                  } me-1`}
                                ></i>
                                {editingProductId === product.goods_id
                                  ? "キャンセル"
                                  : "編集"}
                              </Button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Modal
            isOpen={isModalOpen}
            toggle={() => setIsModalOpen(!isModalOpen)}
            className="modal-dialog-centered modal-lg"
          >
            <ModalHeader
              toggle={() => setIsModalOpen(!isModalOpen)}
              className="border-bottom-0"
            >
              <h5 className="modal-title">商品ブランド管理</h5>
            </ModalHeader>
            <ModalBody className="px-4 py-4">
              <Form className="mb-4">
                <FormGroup className="mb-4">
                  <Label htmlFor="brandName" className="form-label h5 mb-3">
                    新規ブランド名
                  </Label>
                  <div className="d-flex align-items-center">
                    <Input
                      type="text"
                      id="brandName"
                      placeholder="新しいブランド名を入力"
                      onChange={handleNewBrandChange}
                      value={newBrand}
                      className="form-control flex-grow-1 me-3"
                      style={{ maxWidth: "300px" }}
                    />
                    <Button color="primary" onClick={handleBrandRegistration}>
                      登録
                    </Button>
                  </div>
                </FormGroup>
              </Form>
              <h5 className="mb-3 mt-5">既存ブランド一覧</h5>
              <div className="brand-list d-flex flex-wrap">
                {goodsBrandOptions.map(brand => (
                  <Badge
                    key={brand.value}
                    color="light"
                    className="me-2 mb-2 p-2 d-flex align-items-center"
                  >
                    {brand.label}
                    <Button
                      close
                      className="ms-2"
                      onClick={() => handleBrandDeleteClick(brand)}
                    />
                  </Badge>
                ))}
              </div>
            </ModalBody>
          </Modal>
          <Modal
            isOpen={isDeleteConfirmationModalOpen}
            toggle={() => setIsDeleteConfirmationModalOpen(false)}
            className="modal-dialog-centered"
          >
            <ModalHeader toggle={() => setIsDeleteConfirmationModalOpen(false)}>
              ブランド削除の確認
            </ModalHeader>
            <ModalBody>
              <p>
                {deletingBrand
                  ? `"${deletingBrand.label}"を削除してもよろしいですか？`
                  : ""}
              </p>
            </ModalBody>
            <ModalFooter>
              <Button
                color="secondary"
                onClick={() => setIsDeleteConfirmationModalOpen(false)}
              >
                キャンセル
              </Button>
              <Button color="danger" onClick={confirmBrandDelete}>
                削除
              </Button>
            </ModalFooter>
          </Modal>
          <Modal
            isOpen={isConfirmationModalOpen}
            toggle={() => setIsConfirmationModalOpen(!isConfirmationModalOpen)}
          >
            <ModalHeader
              toggle={() =>
                setIsConfirmationModalOpen(!isConfirmationModalOpen)
              }
            >
              ブランド変更確認
            </ModalHeader>
            <ModalBody>
              <p>以下の商品のブランドを変更しますか？</p>
              <ul>
                {Object.keys(selectedGoods)
                  .filter(goodId => selectedGoods[goodId])
                  .map(goodId => {
                    const good = goods.find(
                      good => good.goods_id === Number(goodId)
                    )
                    if (!good) {
                      return <li key={goodId}></li>
                    }
                    return <li key={goodId}>{good.goods_name}</li>
                  })}
              </ul>
              <p>変更するブランド: {newGood.name}</p>
              <div>
                <Button
                  color="danger"
                  onClick={() => setIsConfirmationModalOpen(false)}
                >
                  キャンセル
                </Button>
                <Button color="primary" onClick={confirmBrandChange}>
                  OK
                </Button>
              </div>
            </ModalBody>
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default GoodList

// コンポーネントの外部に以下のスタイルを追加
const styles = `
  .selected-row {
    background-color: #FFE6E6;
  }
  .table-hover tbody tr:hover {
    background-color: #F8F9FA;
  }
  .table th {
    border-top: none;
    font-weight: 600;
  }
  .table td {
    vertical-align: middle;
  }
  .modal-content {
    border-radius: 0.5rem;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  }
  .modal-header {
    background-color: #f8f9fa;
  }
  .brand-list::-webkit-scrollbar {
    width: 6px;
  }
  .brand-list::-webkit-scrollbar-thumb {
    background-color: #adb5bd;
    border-radius: 3px;
  }
  .brand-list::-webkit-scrollbar-track {
    background-color: #f1f3f5;
  }
  .modal-body {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  .form-label.h5 {
    font-weight: 600;
    color: #495057;
  }
  .brand-list .badge {
    font-size: 0.9rem;
    font-weight: normal;
  }
  .brand-list .btn-close {
    font-size: 0.7rem;
    padding: 0.25rem;
  }
`

const styleSheet = document.createElement("style")
styleSheet.type = "text/css"
styleSheet.innerText = styles
document.head.appendChild(styleSheet)
