const apiBaseUrl = process.env.REACT_APP_API_ENDPOINT || ""

//LOGIN
export const POST_LOGIN = `${apiBaseUrl}/api/v2/login`

export const GET_TOKEN = `${apiBaseUrl}/api/v2/token`

//flows
export const GET_FLOWS = `${apiBaseUrl}/api/v2/flows`

//stocks
export const GET_STOCKS = `${apiBaseUrl}/api/v2/stocks`
export const PUT_STOCKS = `${apiBaseUrl}/api/v2/stocks`

//stocks
export const GET_SALES_DAILY = `${apiBaseUrl}/api/v2/sales_daily`

//stocks
export const GET_SALES = `${apiBaseUrl}/api/v2/sales`

//ipcs
export const GET_IPCS = `${apiBaseUrl}/api/v2/ipcs`
export const PUT_IPCS = `${apiBaseUrl}/api/v2/ipcs`
export const DEL_IPCS = `${apiBaseUrl}/api/v2/ipcs`

//goods
export const GET_GOODS = `${apiBaseUrl}/api/v2/goods`
export const POST_GOOD = `${apiBaseUrl}/api/v2/goods`
export const PUT_GOOD = `${apiBaseUrl}/api/v2/goods`
export const DELETE_GOOD = `${apiBaseUrl}/api/v2/goods`

export const GET_GOODS_BRAND = `${apiBaseUrl}/api/v2/goods_brand`
export const PUT_GOODS_BRAND = `${apiBaseUrl}/api/v2/goods_brand`

//marketing volume
export const GET_MARKETING_VOLUME = `${apiBaseUrl}/api/v2/marketing/volume`

//marketing customer
export const GET_MARKETING_CUSTOMER = `${apiBaseUrl}/api/v2/marketing/customer`

//marketing time
export const GET_MARKETING_TIME = `${apiBaseUrl}/api/v2/marketing/time`

//cameras
export const GET_CAMERAS = `${apiBaseUrl}/api/v2/cameras`
export const PUT_CAMERAS = `${apiBaseUrl}/api/v2/cameras`
export const DEL_CAMERAS = `${apiBaseUrl}/api/v2/cameras`

export const GET_CAMERA_INFO = `${apiBaseUrl}/api/v2/camera-info`
export const UPDATE_CAMERA_INFO = `${apiBaseUrl}/api/v2/camera-info`

export const GET_USERS = `${apiBaseUrl}/api/v2/users`
export const PUT_USERS = `${apiBaseUrl}/api/v2/users`

export const GET_SALES_ALL = `${apiBaseUrl}/api/v2/sales_all`

export const GET_CUSTOMERS_DATA = `${apiBaseUrl}/api/v2/customers`
export const PUT_CUSTOMER_DATA = `${apiBaseUrl}/api/v2/customers`

export const GET_ANNOUNCEMENT = `${apiBaseUrl}/api/v2/announcement`

// companies
export const GET_COMPANIES = `${apiBaseUrl}/api/v2/companies`
export const POST_COMPANY = `${apiBaseUrl}/api/v2/companies`
export const PUT_COMPANY = `${apiBaseUrl}/api/v2/companies`
export const DELETE_COMPANY = `${apiBaseUrl}/api/v2/companies`

export const GET_SHELFS = `${apiBaseUrl}/api/v2/shelves`
export const PUT_SHELFS = `${apiBaseUrl}/api/v2/shelves`

export const POST_LOCATION = `${apiBaseUrl}/api/v2/locations`
export const PUT_LOCATION = `${apiBaseUrl}/api/v2/locations`
export const DELETE_LOCATION = `${apiBaseUrl}/api/v2/locations`

export const GET_LOCATION_GROUPS = `${apiBaseUrl}/api/v2/location-groups`
export const UPDATE_LOCATION_GROUP = `${apiBaseUrl}/api/v2/location-groups`
export const REGISTER_LOCATION_GROUP = `${apiBaseUrl}/api/v2/location-groups`
export const DELETE_LOCATION_GROUP = `${apiBaseUrl}/api/v2/location-groups`

export const GET_SOLD_OUT = `${apiBaseUrl}/api/v2/sold-out`
export const POST_PROFILE = `${apiBaseUrl}/api/v2/profile`

export const GET_WAREHOUSES = `${apiBaseUrl}/api/v2/warehouses`
export const POST_WAREHOUSE = `${apiBaseUrl}/api/v2/warehouses`
export const PUT_WAREHOUSE = `${apiBaseUrl}/api/v2/warehouses`
export const DELETE_WAREHOUSE = `${apiBaseUrl}/api/v2/warehouses`

export const GET_VEHICLES = `${apiBaseUrl}/api/v2/vehicles`
export const POST_VEHICLE = `${apiBaseUrl}/api/v2/vehicles`
export const PUT_VEHICLE = `${apiBaseUrl}/api/v2/vehicles`
export const DELETE_VEHICLE = `${apiBaseUrl}/api/v2/vehicles`

export const REGISTER_SURPRISE = `${apiBaseUrl}/api/v2/goods/register_surprise`
export const UNREGISTER_SURPRISE = `${apiBaseUrl}/api/v2/goods/unregister_surprise`
export const GET_GOODS_DETAIL = `${apiBaseUrl}/api/v2/goods`

export const POST_DELIVERY_PLAN = `${apiBaseUrl}/api/v2/delivery-plan`
export const GET_DELIVERY_ITEM = `${apiBaseUrl}/api/v2/delivery-plan/items`
export const GET_DELIVERY_PLANS = `${apiBaseUrl}/api/v2/delivery-plan/type`
export const GET_DELIVERY_PLAN_DETAIL = `${apiBaseUrl}/api/v2/delivery-plan/detail`
export const UPDATE_DELIVERY_PLAN = `${apiBaseUrl}/api/v2/delivery-plan`

export const GET_LINE_USERS = `${apiBaseUrl}/api/v2/line-users`
export const POST_LINE_USER = `${apiBaseUrl}/api/v2/line-users`
export const PUT_LINE_USER = `${apiBaseUrl}/api/v2/line-users`
export const DELETE_LINE_USER = `${apiBaseUrl}/api/v2/line-users`
