import { startOfMonth, subDays, subMonths, subYears } from "date-fns"
import React, { useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
} from "reactstrap"

import Breadcrumbs from "../../components/Common/Breadcrumb"
import { fetchGraphData, updateStockItem } from "../../store/stocks/actions"
import LoadingOverlay from "../Common/LoadingOverlay"
import CommonComponent from "../CommonComponent"

import {
  StyledButton1,
  StyledButton4,
  StyledButtonRow,
  StyledInput1,
} from "../../components/StyledComponents"
import ErrorMessage from "../Common/ErrorMessage"

const Stocks = () => {
  const [locId, setLoc] = useState("")

  const [goodsBrand, setGoodsBrand] = useState(null)
  const [vendor_id, setVendor] = useState(null)
  const [timePeriod, setTimePeriod] = useState("時")
  const [currentDate, setCurrentDate] = useState(new Date())
  const [modal, setModal] = useState(false)
  const [selectedItem, setSelectedItem] = useState(null)
  const [sliderValue, setSliderValue] = useState(0)

  const graphData = useSelector(state => state.stocksReducer.graphData)
  const isLoading = useSelector(state => state.stocksReducer.loading)
  const isLoading1 = useSelector(state => state.stocksReducer.loading1)

  const dispatch = useDispatch()

  const results = graphData && graphData.data ? graphData.data.results : []
  const [stockValue, setStockValue] = useState(0)
  const [switchModal, setSwitchModal] = useState(false)
  const [switchItem, setSwitchItem] = useState(null)
  const [switchStockValue, setSwitchStockValue] = useState(0)
  const [switchGoodsName, setSwitchGoodsName] = useState("")
  const [switchProductExpiry, setSwitchProductExpiry] = useState(null)
  const [switchFullValue, setSwitchFullValue] = useState(1)
  const [switchGoodsNo, setSwitchGoodsNo] = useState("")
  const [switchPrice, setSwitchPrice] = useState(0)
  const [loadingUpdate, setLoadingUpdate] = useState(false)
  const [nowDate, setNowDate] = useState("")

  const [retentionPeriod, setRetentionPeriod] = useState(90) // 滞留期日のデフォルト値

  useEffect(() => {
    // 現在の日付と時間を取得
    const now = new Date()

    // 年/月/日と時間:分をフォーマット
    const formattedDate = `${now.getFullYear()}/${String(
      now.getMonth() + 1
    ).padStart(2, "0")}/${String(now.getDate()).padStart(2, "0")} ${String(
      now.getHours()
    ).padStart(2, "0")}:${String(now.getMinutes()).padStart(2, "0")}`

    setNowDate(formattedDate)
  }, [])

  const [productExpiry, setProductExpiry] = useState(() => {
    const date = new Date()
    date.setDate(date.getDate() + 90)
    return date
  })

  const handleSwitchModalOpen = () => {
    setSwitchItem({ ...selectedItem })
    setSwitchModal(true)
  }

  const handleSwitchGoodsNameChange = e => {
    setSwitchGoodsName(e.target.value)
  }

  const handleSwitchStockChange = e => {
    setSwitchStockValue(parseInt(e.target.value))
  }

  const handleSwitchExpiryChange = e => {
    setSwitchProductExpiry(new Date(e.target.value))
  }

  const decreaseStock = () => {
    if (stockValue > 0 - selectedItem.current_stock) {
      setStockValue(stockValue - 1)
    }
  }

  const increaseStock = () => {
    if (
      stockValue < 100 &&
      sliderValue > stockValue + selectedItem.current_stock
    ) {
      setStockValue(stockValue + 1)
    }
  }

  const handleStockChange = e => {
    const value = parseInt(e.target.value)
    if (value >= 1 && value <= 100) {
      setStockValue(value)
    }
  }

  const fillStock = () => {
    setStockValue(selectedItem.full_stock - selectedItem.current_stock)
  }

  const switchStock = () => {
    let goodsName = selectedItem.goods_name
    const index = goodsName.indexOf("_")
    if (index > -1) {
      goodsName = goodsName.slice(index + 1) // '_の右側の部分だけを取り出す
    }

    setSwitchGoodsName(goodsName)
    setSwitchStockValue(stockValue)
    setSwitchProductExpiry(productExpiry)
    setSwitchFullValue(sliderValue)
    setSwitchModal(true) // 新しい在庫を設定するモーダルを開く
  }

  const decreaseFull = () => {
    if (sliderValue > 1 && sliderValue > selectedItem.current_stock) {
      setSliderValue(sliderValue - 1)
    }
  }

  const increaseFull = () => {
    if (sliderValue < 100) {
      setSliderValue(sliderValue + 1)
    }
  }

  const handleExpiryChange = e => {
    setProductExpiry(new Date(e.target.value))
  }

  const increaseSwitchStock = () => {
    setSwitchStockValue(prevValue => Math.min(prevValue + 1, 100))
  }

  const decreaseSwitchStock = () => {
    setSwitchStockValue(prevValue => Math.max(prevValue - 1, 1))
  }

  const increaseSwitchFull = () => {
    setSwitchFullValue(prevValue => Math.min(prevValue + 1, 100))
  }

  const decreaseSwitchFull = () => {
    setSwitchFullValue(prevValue => Math.max(prevValue - 1, 1))
  }

  const previousDate = useMemo(() => {
    if (timePeriod === "月") {
      return startOfMonth(subYears(currentDate, 1))
    } else if (timePeriod === "日") {
      return startOfMonth(currentDate)
    } else if (timePeriod === "時") {
      return subDays(currentDate, 1)
    } else if (timePeriod === "3か月") {
      return startOfMonth(subMonths(currentDate, 3))
    }
  }, [timePeriod, currentDate])

  function findVendorIdByValue(value) {
    const valueToSearch = Number(value)
    const option = locIdOptions.find(opt => opt.value === valueToSearch)
    return option ? option.vendor_id : null
  }

  const handleRowClick = item => {
    setSelectedItem(item)
    setStockValue(item.full_stock - item.current_stock) // 在庫数
    setSliderValue(item.full_stock)
    setModal(true)
  }

  const handleSliderChange = e => {
    const value = parseInt(e.target.value)
    setSelectedItem({ ...selectedItem, full_stock: value })
    setSliderValue(value)
  }

  const formatDate = date => {
    const d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear()

    return [year, month.padStart(2, "0"), day.padStart(2, "0")].join("-")
  }

  useEffect(() => {
    if (locId !== "") {
      dispatch(
        fetchGraphData(goodsBrand, locId, timePeriod, currentDate, previousDate)
      )
    }
  }, [locId])

  useEffect(() => {
    if (isLoading1 === false && switchModal === true) {
      setSwitchModal(false)
      setModal(false)
    }
  }, [isLoading1])

  const handleSwitchUpdate = () => {
    const updatedItem = {
      ...switchItem,
      goods_name: selectedItem.goods_name,
      switchGoodsName: switchGoodsName,
      switchStockValue: switchStockValue,
      switchFullValue: switchFullValue,
      switchPrice: switchPrice,
      switchGoodsNo: switchGoodsNo,
      productExpiry: switchProductExpiry ? switchProductExpiry : productExpiry,
      machine_address: selectedItem.machine_address,
    }
    dispatch(updateStockItem(updatedItem, locId)) // save changes using your Redux action
  }

  const handleUpdate = () => {
    let finalStockValue =
      vendor_id === 1
        ? selectedItem.current_stock
        : stockValue + selectedItem.current_stock

    const updatedItem = {
      ...switchItem,
      goods_name: selectedItem.goods_name,
      stockValue: finalStockValue,
      productExpiry: productExpiry,
      full_stock: sliderValue,
      retentionPeriod: retentionPeriod, // 滞留日を追加
      machine_address: selectedItem.machine_address,
    }

    dispatch(updateStockItem(updatedItem, locId)) // save changes using your Redux action
    setModal(false)
  }

  const handleRetentionPeriodChange = e => {
    setRetentionPeriod(parseInt(e.target.value))
  }

  const isWithinOneWeek = dateStr => {
    if (!dateStr) return false

    const today = new Date()
    const oneWeekLater = new Date(today)
    oneWeekLater.setDate(today.getDate() + 7)
    const targetDate = new Date(
      dateStr.replace(/年|月/g, "-").replace("日", "")
    )

    return targetDate <= oneWeekLater
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="ホーム" breadcrumbItem="自販機補充" />
          <ErrorMessage />
          <LoadingOverlay isLoading={isLoading1} />
          <Row>
            <Col xs={12}>
              <Card className="bg-secondary">
                <CardBody>
                  <h4 className="card-title mb-4">検索条件</h4>
                  <LoadingOverlay isLoading={isLoading} />
                  <CommonComponent
                    showGoodsBrand={false}
                    showDate={false}
                    showAll={false}
                    goodsBrand={goodsBrand}
                    setGoodsBrand={setGoodsBrand}
                    locId={locId}
                    setLoc={setLoc}
                    currentDate={currentDate}
                    setCurrentDate={setCurrentDate}
                    timePeriod={timePeriod}
                    setTimePeriod={setTimePeriod}
                    previousDate={previousDate}
                    vendor_id={vendor_id}
                    setVendor={setVendor}
                    past_loc={false}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Card>
                <CardBody>
                  <CardTitle>在庫状況</CardTitle>
                  <Table striped responsive>
                    <thead>
                      <tr>
                        <th>商品名</th>
                        <th className="stocks-count">満ﾀﾝ</th>
                        <th className="stocks-count">在庫</th>
                        <th className="stocks-count">不足</th>
                      </tr>
                    </thead>
                    <tbody>
                      {results.length > 0 ? (
                        results.map((result, index) => (
                          <tr
                            key={index}
                            onClick={() => handleRowClick(result)}
                          >
                            <td>
                              {isWithinOneWeek(result.oldest_expiry_date) ? (
                                <span
                                  style={{
                                    color: "red",
                                    fontWeight: "bold",
                                    fontSize: "16px",
                                  }}
                                >
                                  ！
                                </span>
                              ) : isWithinOneWeek(result.oldest_end_date) ? (
                                <span
                                  style={{
                                    color: "blue",
                                    fontWeight: "bold",
                                    fontSize: "16px",
                                  }}
                                >
                                  ！
                                </span>
                              ) : (
                                <span
                                  style={{
                                    display: "inline-block",
                                    width: "16px",
                                    height: "16px",
                                  }}
                                />
                              )}
                              {result.goods_name}
                            </td>
                            <td className="stocks-count">
                              {result.full_stock}
                            </td>
                            <td
                              style={{
                                color:
                                  result.current_stock <= 0 ? "red" : "inherit",
                                fontWeight:
                                  result.current_stock <= 0 ? "bold" : "normal",
                              }}
                              className="stocks-count"
                            >
                              {result.current_stock}
                            </td>
                            <td className="stocks-count">{result.shortage}</td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="4" style={{ textAlign: "center" }}>
                            データがありません
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                  <div style={{ textAlign: "right" }}>{nowDate}現在</div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Modal isOpen={modal} toggle={() => setModal(!modal)}>
            <ModalHeader toggle={() => setModal(!modal)}>
              商品情報更新
            </ModalHeader>
            <ModalBody>
              <FormGroup>
                <label>対象自販機名：</label>
                <h5>
                  <p>{selectedItem?.ipc_name || "N/A"}</p>
                </h5>
              </FormGroup>
              <FormGroup>
                <label>商品名：</label>
                <h5>
                  <p>{selectedItem?.goods_name || "N/A"}</p>
                </h5>
              </FormGroup>
              {selectedItem && (
                <FormGroup
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    gap: "2rem",
                  }}
                >
                  <div>
                    <label>直近の賞味期限：</label>
                    <h5>
                      <a
                        href={`/shelfs?loc_id=${
                          selectedItem.loc_id
                        }&column_no=${
                          selectedItem.goods_name.split("_")[0]
                        }&isStock=1`}
                        rel="noopener noreferrer"
                      >
                        {selectedItem.oldest_expiry_date || "データなし"}
                      </a>
                    </h5>
                  </div>
                  <div>
                    <label>直近の滞留期日：</label>
                    <h5>
                      <a
                        href={`/shelfs?loc_id=${
                          selectedItem.loc_id
                        }&column_no=${
                          selectedItem.goods_name.split("_")[0]
                        }&isStock=1`}
                        rel="noopener noreferrer"
                      >
                        {selectedItem.oldest_end_date || "データなし"}
                      </a>
                    </h5>
                  </div>
                </FormGroup>
              )}
              {vendor_id && vendor_id !== 1 && (
                <FormGroup>
                  <label>滞留期日（日）：</label>
                  <Input
                    type="number"
                    min="1"
                    value={retentionPeriod}
                    onChange={handleRetentionPeriodChange}
                  />
                </FormGroup>
              )}
              <FormGroup>
                <label>満タン数</label>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <StyledButton1 color="secondary" onClick={decreaseFull}>
                    -
                  </StyledButton1>
                  <StyledInput1
                    type="number"
                    min="1"
                    max="100"
                    value={sliderValue}
                    onChange={handleSliderChange}
                    step="1"
                  />
                  <StyledButton1 color="secondary" onClick={increaseFull}>
                    +
                  </StyledButton1>
                </div>
              </FormGroup>
              {vendor_id && vendor_id !== 1 && (
                <FormGroup>
                  <label>
                    補充数(現時点の在庫数：{selectedItem?.current_stock})
                  </label>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <StyledButton1 color="secondary" onClick={decreaseStock}>
                      -
                    </StyledButton1>
                    <StyledInput1
                      type="number"
                      min="1"
                      max="100"
                      value={stockValue}
                      onChange={handleStockChange}
                      step="1"
                    />
                    <StyledButton1 color="secondary" onClick={increaseStock}>
                      +
                    </StyledButton1>
                  </div>
                </FormGroup>
              )}
            </ModalBody>
            <ModalFooter
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
              }}
            >
              <StyledButtonRow>
                <StyledButton4
                  color="secondary"
                  onClick={() => setModal(!modal)}
                >
                  キャンセル
                </StyledButton4>
                <StyledButton4 color="primary" onClick={handleUpdate}>
                  更新
                </StyledButton4>
              </StyledButtonRow>
            </ModalFooter>
          </Modal>

          <Modal
            isOpen={switchModal}
            toggle={() => setSwitchModal(!switchModal)}
          >
            <ModalHeader toggle={() => setSwitchModal(!switchModal)}>
              在庫入れ替え
            </ModalHeader>
            <ModalBody>
              <LoadingOverlay isLoading={isLoading1} />
              <FormGroup>
                <label>商品名</label>
                <Input
                  type="text"
                  value={switchGoodsName}
                  onChange={handleSwitchGoodsNameChange}
                />
              </FormGroup>
              <FormGroup>
                <label>補充数</label>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Button
                    color="secondary"
                    onClick={decreaseSwitchStock}
                    style={{ marginRight: "5px" }}
                  >
                    -
                  </Button>
                  <Input
                    type="number"
                    min="0"
                    max="100"
                    value={switchStockValue}
                    onChange={handleSwitchStockChange}
                    step="1"
                    style={{ marginRight: "5px" }}
                  />
                  <Button color="secondary" onClick={increaseSwitchStock}>
                    +
                  </Button>
                </div>
              </FormGroup>
              <FormGroup>
                <label>満タン</label>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Button
                    color="secondary"
                    onClick={decreaseSwitchFull}
                    style={{ marginRight: "5px" }}
                  >
                    -
                  </Button>
                  <Input
                    type="number"
                    min="1"
                    max="100"
                    value={switchFullValue}
                    onChange={e => setSwitchFullValue(+e.target.value)}
                    step="1"
                    style={{ marginRight: "5px" }}
                  />
                  <Button color="secondary" onClick={increaseSwitchFull}>
                    +
                  </Button>
                </div>
              </FormGroup>
              <FormGroup>
                <label>商品番号（goods_no）</label>
                <Input
                  type="text"
                  value={switchGoodsNo}
                  onChange={e => setSwitchGoodsNo(e.target.value)}
                />
              </FormGroup>
              <FormGroup>
                <label>価格</label>
                <Input
                  type="number"
                  min="0"
                  value={switchPrice}
                  onChange={e => setSwitchPrice(+e.target.value)}
                  step="1"
                />
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              <StyledButtonRow>
                <StyledButton4
                  color="secondary"
                  onClick={() => setSwitchModal(!switchModal)}
                >
                  キャンセル
                </StyledButton4>
                <StyledButton4 color="primary" onClick={handleSwitchUpdate}>
                  更新
                </StyledButton4>
              </StyledButtonRow>
            </ModalFooter>
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Stocks
