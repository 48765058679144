import axios from "axios"
import store from "../store"
import Cookie from "js-cookie"
import { API_ENDPOINT } from "config"
import { LOGIN_SUCCESS } from "store/auth/login/actionTypes"
import * as url from "./url_helper"

//pass new generated access token here

//apply base url for axios
const API_URL = ""

export const axiosApi = axios.create({
  baseURL: API_URL,
})

// レスポンス毎のインターセプト設定
axiosApi.interceptors.response.use(
  response => {
    // 処理成功時の共通処理
    return response
  },
  async error => {
    // 処理失敗時の共通処理
    const originalRequest = error.config
    console.error("Error during request:", error)

    if (
      error.response &&
      error.response.status === 401 &&
      !originalRequest._retry
    ) {
      // 認証エラーの場合、リフレッシュトークンを使ってアクセストークンを更新します
      originalRequest._retry = true

      const refreshToken = Cookie.get("refreshToken")
      if (!refreshToken) {
        Cookie.remove("refreshToken")
        return Promise.reject(error)
      }

      const instance = axios.create() // インターセプターが適用されていない新しいaxiosインスタンスを作成します。

      try {
        const response = await instance.get(`${url.GET_TOKEN}`, {
          headers: {
            Authorization: `Bearer ${refreshToken}`,
          },
        })
        // 新しいリフレッシュトークンをCookieに保存します
        Cookie.set("refreshToken", response.data.refresh_token, { expires: 7 })

        // 新しいアクセストークンをstoreに保存します
        store.dispatch({
          type: LOGIN_SUCCESS,
          payload: {
            user: localStorage.getItem("authUser"),
            token: "Bearer " + response.data.access_token,
          },
        })

        originalRequest.headers["Authorization"] =
          "Bearer " + response.data.access_token

        return axiosApi(originalRequest)
      } catch (refreshError) {
        if (refreshError.response && refreshError.response.status === 401) {
          // リフレッシュトークンが無効化された場合、ユーザーをログアウトさせる
          localStorage.removeItem("authUser")
        } else {
          // その他のエラーはそのままスロー
          throw refreshError
        }
      }
    }

    return Promise.reject(error)
  }
)

axiosApi.interceptors.request.use(
  config => {
    const state = store.getState()

    if (state.Login.token) {
      const token = state.Login.token

      if (!token.startsWith("Bearer ")) {
        config.headers.Authorization = `Bearer ${token}`
      } else {
        config.headers.Authorization = token
      }
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

export async function get(url, config = {}) {
  try {
    const response = await axiosApi.get(url, { ...config })
    // console.log(response)
    return response.data
  } catch (error) {
    if (error.response && error.response.status === 401) {
      localStorage.removeItem("authUser")
    }

    throw error
  }
}

export async function post(url, data, config = {}) {
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then(response => response.data)
}
