export * from "./layout/actions"
export * from "./auth/login/actions"
export * from "./cameraInfo/actions"  // この行を追加

// Authentication module
export * from "./auth/login/actions"

export const PUT_ERROR = "PUT_ERROR"

export const CLEAR_ERROR = "CLEAR_ERROR"

export const putError = error => ({
  type: PUT_ERROR,
  payload: error,
})

export const clearError = () => ({
  type: CLEAR_ERROR,
})

// カメラ情報関連のアクションをインポート
import {
  fetchCameraInfo,
  fetchCameraInfoSuccess,
  fetchCameraInfoError,
  updateCameraInfo,
  updateCameraInfoSuccess,
  updateCameraInfoError,
} from './cameraInfo/actions'

// カメラ情報関連のアクションをエクスポート
export {
  fetchCameraInfo,
  fetchCameraInfoSuccess,
  fetchCameraInfoError,
  updateCameraInfo,
  updateCameraInfoSuccess,
  updateCameraInfoError,
}
