import React, { Component } from "react"
import { Doughnut } from "react-chartjs-2"
import { Chart, registerables } from "chart.js"
import ChartDataLabels from "chartjs-plugin-datalabels"

Chart.register(...registerables)
Chart.register(ChartDataLabels)

class DountChart extends Component {
  render() {
    const { labels, data } = this.props

    // Top 5の商品に対応する色を定義
    const top5Colors = [
      "rgba(75, 192, 192, 0.7)", // シアン
      "rgba(255, 206, 86, 0.7)", // 黄色
      "rgba(153, 102, 255, 0.7)", // 紫色
      "rgba(255, 99, 32, 0.7)", // オレンジ
      "rgba(75, 255, 75, 0.7)", // 緑色
      "rgba(255, 159, 64, 0.7)", // ピーチ
      "rgba(54, 162, 235, 0.7)", // 青色
      "rgba(255, 99, 132, 0.7)", // ローズピンク
      "rgba(173, 216, 230, 0.7)", // ライトブルー
      "rgba(255, 159, 243, 0.7)", // ライトピンク
      "rgba(220, 220, 220, 0.7)", // 薄い灰色 その他
    ]

    const dountChartData = {
      labels: labels,
      datasets: [
        {
          data: data,
          backgroundColor: top5Colors,
        },
      ],
    }

    const dountChartOptions = {
      plugins: {
        datalabels: {
          color: "#fff",
          formatter: function (value, context) {
            return value.toLocaleString() // 数値をカンマ区切りで表示
          },
        },
      },
      cutoutPercentage: 50,
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        display: false,
      },
    }

    const chartStyle = {
      height: "400px",
    }

    return (
      <React.Fragment>
        {data && data.length ? (
          <div style={chartStyle}>
            <Doughnut data={dountChartData} options={dountChartOptions} />
          </div>
        ) : (
          <div style={chartStyle}>
            <p>対象データなし</p>
          </div>
        )}
      </React.Fragment>
    )
  }
}

export default DountChart
