import React, { useState, useEffect } from "react"
import { useDispatch } from "react-redux"
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  FormGroup,
  Label,
  Input,
} from "reactstrap"
import { updateGood, deleteGood } from "../../../store/goods/actions"

const UpdateItemModal = ({ isOpen, toggle, item, onUpdate, onDelete }) => {
  const dispatch = useDispatch()

  const [updatedItem, setUpdatedItem] = useState({
    goods_no: "",
    goods_name: "",
    stock_count: 0,
  })

  useEffect(() => {
    if (item) {
      setUpdatedItem({
        goods_id: item.goods_id || "",
        goods_no: item.goods_no || "",
        goods_name: item.goods_name || "",
        stock_count: item.stock_count || 0,
      })
    }
  }, [item])

  const handleChange = e => {
    const { name, value } = e.target
    setUpdatedItem({
      ...updatedItem,
      [name]: value,
    })
  }

  const handleUpdate = () => {
    dispatch(updateGood(updatedItem))
    toggle()
  }

  const handleDelete = () => {
    if (window.confirm("本当にこの商品を削除しますか？")) {
      dispatch(deleteGood(item.goods_id))
      toggle()
    }
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>商品情報を更新</ModalHeader>
      <ModalBody>
        <FormGroup>
          <Label htmlFor="goods_no">商品コード</Label>
          <Input
            id="goods_no"
            name="goods_no"
            type="text"
            value={updatedItem.goods_no}
            onChange={handleChange}
          />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="goods_name">商品名</Label>
          <Input
            id="goods_name"
            name="goods_name"
            type="text"
            value={updatedItem.goods_name}
            onChange={handleChange}
          />
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>
          キャンセル
        </Button>
        <Button color="danger" onClick={handleDelete}>
          削除
        </Button>
        <Button color="primary" onClick={handleUpdate}>
          更新
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default UpdateItemModal
