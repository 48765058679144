import React, { useEffect, useState } from "react"
import DatePicker from "react-datepicker"
import { useDispatch, useSelector } from "react-redux"
import Select from "react-select"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Modal,
  Row,
  FormGroup,
  Input,
  Label,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { fetchCamerasData, updateCameras } from "../../store/cameras/actions"
import ErrorMessage from "../Common/ErrorMessage"
import LoadingOverlay from "../Common/LoadingOverlay"

const Cameras = () => {
  const [selectedVendingMachine, setSelectedVendingMachine] = useState(null)
  const [modal, setModal] = useState(false)
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [note, setNote] = useState("")
  const [isDeleteChecked, setIsDeleteChecked] = useState(false)

  const [tempStartDate, setTempStartDate] = useState(null)
  const [tempEndDate, setTempEndDate] = useState(null)

  const [tempLoc, setTempLoc] = useState(null)
  const [tempNote, setTempNote] = useState("")
  const [statusFilter, setStatusFilter] = useState("稼働中及び未割当")

  const dispatch = useDispatch()

  const camerasData = useSelector(state => state.camerasReducer.camerasData)
  const isLoading = useSelector(state => state.camerasReducer.loading)

  const cameras =
    camerasData && camerasData.data ? camerasData.data.results : []
  const locList =
    camerasData && camerasData.data ? camerasData.data.results2 : []

  const [sortField, setSortField] = useState("receive_end")
  const [sortDirection, setSortDirection] = useState("desc")

  const [sortedCameras, setSortedCameras] = useState([])

  const [isFormValid, setIsFormValid] = useState(false)

  const [searchQuery, setSearchQuery] = useState("")

  useEffect(() => {
    dispatch(fetchCamerasData())
  }, [dispatch])

  useEffect(() => {
    setSortedCameras(cameras)
  }, [cameras])

  useEffect(() => {
    if (tempStartDate && tempEndDate && tempLoc && tempLoc.value) {
      setIsFormValid(true)
    } else {
      setIsFormValid(false)
    }
  }, [tempStartDate, tempEndDate, tempLoc])

  useEffect(() => {
    if (cameras.length > 0) {
      sortCameras(sortField, sortDirection)
    }
  }, [cameras, sortField, sortDirection, statusFilter])

  const sortCameras = (field, direction) => {
    const sorted = [...cameras].sort((a, b) => {
      if (
        field === "receive_start" ||
        field === "receive_end" ||
        field === "start_at" ||
        field === "end_at"
      ) {
        const dateA = new Date(a[field])
        const dateB = new Date(b[field])
        if (dateA < dateB) {
          return direction === "asc" ? -1 : 1
        }
        if (dateA > dateB) {
          return direction === "asc" ? 1 : -1
        }
        return 0
      } else {
        if (a[field] < b[field]) {
          return direction === "asc" ? -1 : 1
        }
        if (a[field] > b[field]) {
          return direction === "asc" ? 1 : -1
        }
        return 0
      }
    })
    let updatedCameras = [...sorted]
    if (statusFilter) {
      if (statusFilter === "稼働中及び未割当") {
        updatedCameras = updatedCameras.filter(
          camera => camera.status === "稼働中" || camera.status === "未割当"
        )
      } else {
        updatedCameras = updatedCameras.filter(
          camera => camera.status === statusFilter
        )
      }
    }
    setSortedCameras(updatedCameras)
  }

  const handleSort = field => {
    const direction =
      sortField === field && sortDirection === "asc" ? "desc" : "asc"
    setSortField(field)
    setSortDirection(direction)
    sortCameras(field, direction)
  }


  const handleVendingMachineClick = vendingMachine => {
    setSelectedVendingMachine(vendingMachine)
    setTempStartDate(vendingMachine.start_at ? new Date(vendingMachine.start_at) : null)
    setTempEndDate(vendingMachine.end_at ? new Date(vendingMachine.end_at) : null)
    setTempLoc({ label: `${vendingMachine.loc_code} - ${vendingMachine.loc_name}`, value: vendingMachine.loc_id })
    setTempNote(vendingMachine.note || "")
    setModal(true)
  }

  const handleIpcChange = selectedOption => {
    setTempLoc(selectedOption)
  }

  const handleConfirmChanges = () => {
    const updatedData = {
      camera_id: selectedVendingMachine.camera_id,
      uuid: selectedVendingMachine.uuid,
      loc_id: tempLoc.value,
      start_at: tempStartDate,
      end_at: tempEndDate,
      note: tempNote,
    }

    dispatch(updateCameras(updatedData))
    toggle()
  }

  const toggle = () => setModal(!modal)

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            maintitle="main"
            title="管理"
            breadcrumbItem="Prime-eye機器管理"
          />
          <ErrorMessage />
          <Row>
            <Col xs={12}>
              <Card>
                <CardBody>
                  <CardTitle>Prime-eye機器管理</CardTitle>
                  <LoadingOverlay isLoading={isLoading} />
                  <div
                    style={{
                      marginTop: "10px",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <div style={{ width: "100%", marginBottom: "10px" }}>
                      <input
                        type="text"
                        placeholder="カメラを検索..."
                        value={searchQuery}
                        onChange={e => setSearchQuery(e.target.value)}
                        style={{ width: "100%" }}
                      />
                    </div>
                    <div style={{ display: "flex", maxWidth: "300px", width: "100%" }}>
                      <button
                        onClick={() =>
                          setStatusFilter(prevFilter =>
                            prevFilter === "稼働中" ? "" : "稼働中"
                          )
                        }
                        className={`btn btn-sm ${
                          statusFilter === "稼働中"
                            ? "btn-primary"
                            : "btn-outline-primary"
                        }`}
                        style={{ flex: 1, marginRight: "5px", flexShrink: 0 }}
                      >
                        稼働中
                      </button>
                      <button
                        onClick={() =>
                          setStatusFilter(prevFilter =>
                            prevFilter === "稼働中及び未割当"
                              ? ""
                              : "稼働中及び未割当"
                          )
                        }
                        className={`btn btn-sm ${
                          statusFilter === "稼働中及び未割当"
                            ? "btn-primary"
                            : "btn-outline-primary"
                        }`}
                        style={{ flex: 1, marginRight: "5px", flexShrink: 0 }}
                      >
                        稼働中及び未割当
                      </button>
                      <button
                        onClick={() =>
                          setStatusFilter(prevFilter =>
                            prevFilter === "未割当" ? "" : "未割当"
                          )
                        }
                        className={`btn btn-sm ${
                          statusFilter === "未割当"
                            ? "btn-primary"
                            : "btn-outline-primary"
                        }`}
                        style={{ flex: 1, marginRight: "5px", flexShrink: 0 }}
                      >
                        未割当
                      </button>
                      <button
                        onClick={() =>
                          setStatusFilter(prevFilter =>
                            prevFilter === "終了" ? "" : "終了"
                          )
                        }
                        className={`btn btn-sm ${
                          statusFilter === "終了"
                            ? "btn-primary"
                            : "btn-outline-primary"
                        }`}
                        style={{ flex: 1, flexShrink: 0 }}
                      >
                        終了
                      </button>
                    </div>
                  </div>
                  <table className="table">
                    <thead>
                      <tr>
                        <th onClick={() => handleSort("status")} style={{ width: "5%" }}>状態</th>
                        <th onClick={() => handleSort("uuid")} style={{ width: "20%" }}>UUID</th>
                        <th onClick={() => handleSort("receive_start")} style={{ width: "10%" }}>受信開始</th>
                        <th onClick={() => handleSort("receive_end")} style={{ width: "10%" }}>受信終了</th>
                        <th onClick={() => handleSort("loc_name")} style={{ width: "20%" }}>ロケーション名</th>
                        <th onClick={() => handleSort("start_at")} style={{ width: "10%" }}>開始日時</th>
                        <th onClick={() => handleSort("end_at")} style={{ width: "10%" }}>終了日時</th>
                        <th style={{ width: "15%" }}>備考欄</th>
                      </tr>
                    </thead>
                    <tbody>
                      {sortedCameras.map((machine, index) => (
                        <tr
                          key={`${machine.uuid}-${index}`}
                          onClick={() => handleVendingMachineClick(machine)}
                        >
                          <td>{machine.status}</td>
                          <td>{machine.uuid}</td>
                          <td>{machine.receive_start}</td>
                          <td>{machine.receive_end}</td>
                          <td>{machine.loc_name}</td>
                          <td>{machine.start_at}</td>
                          <td>{machine.end_at}</td>
                          <td>{machine.note}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <Modal isOpen={modal} toggle={toggle}>
                    <div className="modal-body">
                      <FormGroup row>
                        <Col>
                          <Label htmlFor="startDate" style={{ fontSize: "0.8rem" }}>開始日</Label>
                          <DatePicker
                            id="startDate"
                            selected={tempStartDate}
                            onChange={setTempStartDate}
                            showTimeSelect
                            dateFormat="yyyy/MM/dd HH:mm"
                            timeFormat="HH:mm"
                            timeIntervals={15}
                          />
                        </Col>
                        <Col>
                          <Label htmlFor="endDate" style={{ fontSize: "0.8rem" }}>終了日</Label>
                          <DatePicker
                            id="endDate"
                            selected={tempEndDate}
                            onChange={setTempEndDate}
                            showTimeSelect
                            dateFormat="yyyy/MM/dd HH:mm"
                            timeFormat="HH:mm"
                            timeIntervals={15}
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup>
                        <Label style={{ fontSize: "0.8rem" }}>ロケーション</Label>
                        <Select
                          value={tempLoc}
                          onChange={handleIpcChange}
                          options={locList.map(loc => ({
                            value: loc.loc_id,
                            label: `${loc.loc_code} - ${loc.loc_name}`
                          }))}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label style={{ fontSize: "0.8rem" }}>備考</Label>
                        <textarea
                          className="form-control"
                          rows="4"
                          value={tempNote}
                          onChange={e => setTempNote(e.target.value)}
                          placeholder="備考を入力してください..."
                        ></textarea>
                      </FormGroup>
                    </div>
                    <div className="modal-footer" style={{ justifyContent: "center" }}>
                      <Button type="button" onClick={toggle} color="secondary" style={{ width: "45%" }}>
                        キャンセル
                      </Button>
                      <Button type="button" onClick={handleConfirmChanges} color="primary" style={{ width: "45%" }}>
                        更新
                      </Button>
                    </div>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Cameras