import { call, put, takeEvery } from "redux-saga/effects"
import {
  FETCH_LINE_USERS,
  POST_LINK_USER,
  PUT_LINK_USER,
  DELETE_LINE_USER,
} from "./actionTypes"
import {
  fetchLineUsersSuccess,
  fetchLineUsersFail,
  postLineUserSuccess,
  postLineUserFail,
  putLineUserSuccess,
  putLineUserFail,
  deleteLineUserSuccess,
  deleteLineUserFail,
} from "./actions"
import {
  fetchLineUsers,
  linkLineUser,
  unlinkLineUser,
  deleteLineUser,
} from "../../helpers/backend_helper"

function* fetchLineUsersSaga(action) {
  try {
    const { source } = action.payload
    const response = yield call(fetchLineUsers, source)
    yield put(fetchLineUsersSuccess(response))
  } catch (error) {
    yield put(fetchLineUsersFail(error))
  }
}

function* postLineUserSaga(action) {
  try {
    const { lineUserId, appUserId } = action.payload
    const response = yield call(linkLineUser, lineUserId, appUserId)
    yield put(postLineUserSuccess(response))
  } catch (error) {
    yield put(postLineUserFail(error))
  }
}

function* putLineUserSaga(action) {
  try {
    const response = yield call(unlinkLineUser, action.payload)
    yield put(putLineUserSuccess(response))
  } catch (error) {
    yield put(putLineUserFail(error))
  }
}

function* deleteLineUserSaga(action) {
  try {
    yield call(deleteLineUser, action.payload)
    yield put(deleteLineUserSuccess(action.payload))
  } catch (error) {
    yield put(deleteLineUserFail(error))
  }
}

function* lineUserSaga() {
  yield takeEvery(FETCH_LINE_USERS, fetchLineUsersSaga)
  yield takeEvery(POST_LINK_USER, postLineUserSaga)
  yield takeEvery(PUT_LINK_USER, putLineUserSaga)
  yield takeEvery(DELETE_LINE_USER, deleteLineUserSaga)
}

export default lineUserSaga
